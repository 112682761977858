/**************************************************
 * Nombre:       DetalleNosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography} from "@mui/material";
import {TEXTOSUAVE} from "../../Constantes";


const DetalleNosotros = ({id:item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{pb: 4}}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{
                    fontSize: 22,
                    fontFamily: "Montserrat",
                    fontWeight: 800,
                    textAlign: "center"
                }}>{item.nombre}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <Typography sx={{fontSize: 18, fontWeight: 400, textAlign: "center"}}>{item.descripcion}</Typography>
            </Grid>

        </Grid>
    )
}

export default DetalleNosotros;