/**************************************************
 * Nombre:       Dialogo_Cheking
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext, useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Parte from "../Formularios/Formulario_Parte";
import {LoadingButton} from "@mui/lab";
import {contextoCheking} from "../Secciones/Checking";


const Dialogo_Cheking = ({cerrar, id, setTitulo}) => {
    const [identificador, setIdentificador] = useState('')
    const [preguntas, setPreguntas] = useState([])
    const {Formulario, props, entidad, setEntidad} = useFormulario({
        Form: Formulario_Parte,
        nombre: 'parte',
        preguntas: preguntas
    })
    const [cargando, setCargando] = useState(false)
    const [nombre, setNombre] = useState('')
    const cData = useContext(contextoCheking)

    const guardar = () => {


        if (verificarTodosLlenos()) {
            setCargando(true)
            let obj = {}
            obj[identificador] = entidad
            cData.setDatosListas({...cData.datosListas, ...obj})
            setCargando(false)
            cerrar()
        } else {
            alert('Todas las preguntas deben ser contestadas\nEn el caso que la unidad no posea esta cualidad seleccione la opcion \"no aplica\"')
        }


    }


    const verificarTodosLlenos = () => {

        for (let i = 0; i < preguntas.length; i++) {
            if (entidad[preguntas[i]] === undefined) {
                return false
            }
        }

        return true
    }

    useEffect(() => {
        setTitulo(id.nombre)
        setNombre(id.nombre)
        setIdentificador(id.id)
        setPreguntas(id.preguntas)

        if (cData.datosListas[id.id]) {
            setEntidad(cData.datosListas[id.id])
        }


    }, [id, cData])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                {!entidad.img &&
                <Typography sx={{textAlign: 'center'}}>
                    {'Ingrese la fotografia de ' + nombre + ' para continuar'}
                </Typography>
                }
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>

            {entidad.img &&
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <LoadingButton
                    variant={'contained'}
                    loading={cargando}
                    onClick={() => guardar()}
                >
                    Guardar Lista
                </LoadingButton>
            </Grid>
            }


        </Grid>
    )
}

export default Dialogo_Cheking;