/**************************************************
 * Nombre:       MenuHamburguesa
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, ButtonBase, Drawer, Grid, Typography} from "@mui/material";
import {CloseSquare, HambergerMenu, Location} from "iconsax-react";



const MenuHamburguesa = ({setOpen}) => {


    const abrir = () => {
        setOpen(true)
    }



    return (
        <>



            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <ButtonBase onClick={() => abrir()}>

                    <HambergerMenu color={"#fff"} size={"2rem"}/>
                </ButtonBase>


            </Grid>
        </>
    )
}

export default MenuHamburguesa;

