/**************************************************
 * Nombre:       ItemsTestimonios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import YouTube from 'react-youtube';
import {TEXTOACENTO} from "../../../Constantes";
import {theme} from "../../../Tema";


const ItemsTestimonios = ({item, index}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    const opts = {
        height: sCell ? '180' : '250',
        width: sCell ? '330' : '480',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <Grid
            component={"div"}
            data-aos="fade-up"
            data-aos-delay={300 * index}
            data-aos-duration="1500"
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography
                            sx={{fontSize: 16, fontWeight: 600, fontFamily: "Montserrat"}}>{item.nombre}</Typography>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                        <Typography sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: TEXTOACENTO,
                        }}>{item.empresa}</Typography>
                    </Grid>

                </Grid>
            </Grid>


           <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}} >
                <YouTube
                    videoId={item.url} // defaults -> null
                    id={item.url} // defaults -> null
                    title={"titulo"}
                    opts={opts}

                />
            </Grid>


        </Grid>
    )
}

export default ItemsTestimonios;