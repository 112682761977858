/**************************************************
 * Nombre:       Cifras
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import mapa from '../../Recursos/mapa.webp'
import fondocifra from '../../Recursos/fondocifra.svg'
import {CountUp, useCountUp} from 'use-count-up'
import {theme} from "../../Tema";
import {useInView} from "react-intersection-observer";


const Cifras = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [ref, inView] = useInView();


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={mapa} width={"auto"} height={sCell ? 160 : 380} alt={"Cifras de la empresa"}  title={"Cifras de la empresa"}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? -18 : -32}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={sCell ? 2 : 0}
                        ref={ref}
                    >
                        {cifras.map((item, index) => {
                            return (
                                <Grid item container lg={3} sm={4} xs={6} sx={{justifyContent: "center"}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: "center", zIndex: 2}}>
                                            <img src={fondocifra} width={"auto"} height={sCell ? 30 : 50}
                                                 alt={"cifra"}  title={"cifra"}/>
                                        </Grid>

                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{
                                                  justifyContent: "center",
                                                  marginTop: sCell ? -3.7 : -6,
                                                  zIndex: 1,
                                                  pl: 0
                                              }}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: sCell ? 18 : 26,
                                                fontWeight: 700
                                            }}>
                                                <CountUp
                                                    isCounting={inView}
                                                    end={item.numero}
                                                    duration={5}
                                                    thousandsSeparator={"."}
                                                    start={1000}
                                                    easing={"easeInCubic"}

                                                />
                                            </Typography>

                                        </Grid>


                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: "center", marginTop: 2, zIndex: 1, px: 6}}>
                                            <Typography sx={{
                                                fontFamily: "Montserrat",
                                                fontSize: sCell ? 16 : 22,
                                                fontWeight: 700,
                                                textAlign: "center"
                                            }}>
                                                {item.nombre}
                                            </Typography>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            )
                        })}


                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Cifras;

const cifras = [
    {
        nombre: "Despachos Nacionales",
        numero: 27576
    },
    {
        nombre: "Despachos Internacionales",
        numero: 3153
    },
    {
        nombre: "Cargas Aseguradas",
        numero: 9416
    },
]