/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logoblanco.svg'
import Menu from "./Componentes/Menu";
import BarraSuperior from "./Componentes/BarraSuperior";
import {theme} from "../../Tema";
import MenuHamburguesa from "./Componentes/MenuHamburguesa";
import BarrasMenu from "./Componentes/BarrasMenu";


const Barra = ({setOpen}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            component={"div"}
            data-aos="fade-down"
            data-aos-delay={0}
            data-aos-duration="1500"
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{backgroundColor: "#000"}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: 3, py: 1}}
            >

                <Grid item lg={2} sm={1} xs={6} sx={{marginTop: 1}}>
                    <img src={logo} width={sCell ? 160 : 180} height={"auto"} alt={"Logo International Logistics MD"} title={"Logo International Logistics MD"} />
                </Grid>

                <Grid item lg={10} sm={11} xs={6}  >
                    {masSM ?
                        <BarrasMenu/> :
                        <MenuHamburguesa setOpen={setOpen}  />
                    }
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Barra;



