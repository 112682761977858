/**************************************************
 * Nombre:       Tarjeta_Checking
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext, useEffect, useState} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {TickCircle, TickSquare} from "iconsax-react";
import {contextoCheking} from "../Secciones/Checking";


const Tarjeta_Checking = ({valores, click}) => {
    const cData = useContext(contextoCheking)
    const [realizado, setRealizado] = useState(false)


    useEffect(() => {
        if (cData && valores && cData.datosListas && cData.datosListas[valores.id]) {
            setRealizado(true)
        } else {
            setRealizado(false)
        }

    }, [cData, valores])
    return (
        <ButtonBase sx={{width: '100%'}} onClick={() => click(valores)}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{boxShadow: 4}}
            >

                {realizado &&
                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "flex-end", p: 2, marginBottom: -14, zIndex: 2}}>
                    <TickCircle variant={'Bold'} size={'3rem'}/>
                </Grid>
                }

                <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 1}}>
                    <img src={valores.img} width={'100%'} height={'auto'}/>
                </Grid>

            </Grid>
        </ButtonBase>
    )
}

export default Tarjeta_Checking;