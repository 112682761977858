/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {
    Barcode,
    Flag,
    Truck, User
} from "iconsax-react";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {copiarPortapapeles} from "../../Utilidades/copiarPortapapeles";
import {DIRECCIONURL} from "../../Constantes";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Cheking_Ver from "../Dialogos/Dialogo_Cheking_Ver";


const Tarjeta_checking_Dash = ({valores, click, difiere}) => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Cheking_Ver})

    return (

        <CardBase valores={valores} sombra={0}>

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{backgroundColor: '#EBEBEB', borderRadius: 2, pt: 0.4, px: 1, pb: 1}}
                spacing={1}
            >

                <Grid item lg={2} sm={6} xs={6}>
                    <CardBase.FechaComponente dato1={'fecha'}>
                        <CardBase.IconTexto Icono={Barcode} dato1={"placa"} peso={800}/>
                    </CardBase.FechaComponente>
                </Grid>

                <Grid item lg={2} sm={6} xs={6}>
                    <CardBase.IconTexto Icono={Truck} dato1={"tipo"}/>
                </Grid>

                <Grid item lg={3} sm={6} xs={6}>
                    <CardBase.IconTexto Icono={User} dato1={"nombre"}/>
                </Grid>

                <Grid item lg={2} sm={6} xs={6}>
                    <CardBase.IconTexto Icono={Flag} dato1={"estado"}/>
                </Grid>


                <Grid item container lg={3} sm={5} xs={12} sx={{justifyContent: "flex-end"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >


                        <Button
                            onClick={() => abrir(valores)}
                            color={'primary'} sx={{px: 2, py: 0.1}}>ver</Button>


                        <Button
                            onClick={() => click(valores)}
                            color={'secondary'} sx={{px: 2, py: 0.1}}>Editar</Button>


                        <Button
                            onClick={() => copiarPortapapeles(DIRECCIONURL + 'Checking/' + valores.placa)}
                            color={'secondary'} sx={{px: 2, py: 0.1}}>Cop. Link</Button>


                    </Grid>
                </Grid>

            </Grid>

        </CardBase>


    )
}

export default Tarjeta_checking_Dash;