/**************************************************
 * Nombre:       Formuario_Checking_Nuevo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {
    Barcode, BinanceCoin, Bucket, Cake,
    Call,
    CardTick1,
    Flag,
    Health, Location, Lock,
    Personalcard,
    SecuritySafe, SecurityUser, Tag,
    Truck, TruckFast,
    User,
    UserOctagon, UserTick, WeightMeter
} from "iconsax-react";
import {getFechaTimestampSencillo} from "../../Utilidades/getFechaTimestampSencillo";
import {CARROSERIAS, ESTADOS, ESTADOSCHEKING, TIPOS} from "../../Constantes";


const Formuario_Checking_Ver = ({...props}) => {


    const DatosUnidad = ({datos, titulo}) => {
        return (
            <>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={titulo} lg={6}/>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{px: 4, marginTop: 2}}
                >


                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: "center", marginBottom: 2}}>
                        <img src={datos.img} width={'90%'} height={'auto'}/>
                    </Grid>

                    {Object.entries(datos).sort().map(([key, dato]) => {

                        if (key === 'img') {
                            return ''
                        } else {

                            return (
                                <Grid item lg={12} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        sx={{borderBottom: 1, borderColor: '#00000050'}}
                                    >

                                        <Grid item lg={9} sm={12} xs={12}>
                                            <Typography>{key}</Typography>
                                        </Grid>
                                        <Grid item container lg={3} sm={12} xs={12}
                                              sx={{justifyContent: "flex-end"}}>
                                            <Typography sx={{fontWeight: 600}}>{dato}</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>


                            )
                        }
                    })}

                </Grid>

            </>
        )
    }


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos del conductor '} lg={7}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Nombres y Apellidos'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Personalcard} label={'Cc:'} dato={'cc'} type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Call} label={'Celular'} dato={'celular'} type={'phone'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={CardTick1} label={'Licencia o Pase #'} dato={'pase'} type={'number'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Planilla Pago Seguridad Social'} sm={8} xs={8} lg={8}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Health} label={'EPS'} dato={'eps'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={SecuritySafe} label={'ARP'} dato={'arp'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconFecha label={'Vigente Hasta'} dato={'vigente'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos Vehiculo'} sm={6} xs={4} lg={4}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Tipo'} dato={'tipo'} opciones={TIPOS}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Numero Ejes'} dato={'ejes'} opciones={[2, 3, 4]}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Carroceria'} dato={'carroseria'}
                                              opciones={CARROSERIAS}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={BinanceCoin} label={'Marca'} dato={'marca'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Barcode} label={'Placa'} editable={false} dato={'placa'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={WeightMeter} label={'Capacidad en KG'} dato={'tara'}
                                        type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Bucket} label={'Color'} dato={'color'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Tag} label={'Linea'} dato={'modelo'}/>

                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Cake} label={'Año'} dato={'year'} type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={TruckFast} label={'# de Motor'} dato={'motor'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Truck} label={'# de Chasis'} dato={'chasis'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos Gps del vehiculo'} sm={6} xs={4} lg={6}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Location} label={'Empresa satelital'} dato={'link'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={SecurityUser} label={'Cuenta (si aplica)'} dato={'cuenta'}/>
                </Grid>


                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={UserTick} label={'Usuario'} dato={'usuario'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Lock} label={'Clave de ingreso'} dato={'clave'}/>
                </Grid>

                {props.entidad && props.entidad.datos &&

                    <>

                        {props.entidad.datos.unidad &&
                            <DatosUnidad titulo={'Unidad Carga'} datos={props.entidad.datos.unidad}/>
                        }

                        {props.entidad.datos.furgon &&
                            <DatosUnidad titulo={'Para Furgon'} datos={props.entidad.datos.furgon}/>
                        }

                        {props.entidad.datos.cisterna &&
                            <DatosUnidad titulo={'Para Cisterna'} datos={props.entidad.datos.cisterna}/>
                        }

                        {props.entidad.datos.plataforma &&
                            <DatosUnidad titulo={'Para Plataforma Chais y Similares'}
                                         datos={props.entidad.datos.plataforma}/>
                        }

                        {props.entidad.datos.camion &&
                            <DatosUnidad titulo={'Para Camiones (tractores / cabezales)'}
                                         datos={props.entidad.datos.camion}/>
                        }

                        {props.entidad.datos.interior &&
                            <DatosUnidad titulo={'Complementarios'} datos={props.entidad.datos.interior}/>
                        }


                    </>

                }

                <Grid item lg={12} sm={12} xs={12}>
                    <Typography>{'Firma Conductor: ' + getFechaTimestampSencillo(props.entidad.fechaDiligenciado)}</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={props.entidad.firma}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                    <FormBase.InputIcon Icono={UserOctagon} label={'Aprobo'} dato={'aprobo'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                    <FormBase.InputIconSelect Icono={Flag} label={'Estado'} dato={'estado'} opciones={ESTADOSCHEKING}/>
                </Grid>

            </Grid>
        </FormBase>
    )
}

export default Formuario_Checking_Ver;