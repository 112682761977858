/**************************************************
 * Nombre:       Formulario_Boletin
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {
    FormBase,
    ImagenCustom,
    ImagenDimencion, InputArchivo,
    InputIconFecha
} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Link2, Message, Text, TickCircle} from "iconsax-react";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {subirArchivo} from "../../Servicios/Storage/subirArchivo";


const Formulario_Boletin = (props) => {

    return (
        <FormBase  {...props}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item lg={3} sm={12} xs={12}>

                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.ImagenDimencion carpeta={'boletines'} funcion={subirUnaImagenCroper} dato={'img'}/>
                </Grid>

                <Grid item lg={3} sm={12} xs={12}>

                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputIconFecha label={"Fecha"} dato={'fecha'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Text} label={"Titulo"} dato={'titulo'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message} label={"Resumen"} dato={'resumen'} lineas={6}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={TickCircle} label={"Punto uno"} dato={'uno'} lineas={3}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={TickCircle} label={"Punto dos"} dato={'dos'} lineas={3}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={TickCircle} label={"Punto tres"} dato={'tres'} lineas={3}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={TickCircle} label={"Punto cuatro"} dato={'cuatro'} lineas={3}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={TickCircle} label={"Punto cinco"} dato={'cinco'} lineas={3}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={TickCircle} label={"Punto seis"} dato={'seis'} lineas={3}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message} label={"Noticia completa"} dato={'noticia'} lineas={10}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Link2} label={"Link de fuente"} dato={'fuente'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputArchivo
                        label={'Cargar Archivo'} dato={'fuente'} funcion={subirArchivo}
                        nombre={props.entidad.nombre || 'NA'}
                        extencion={props.entidad.extencion || ''}
                        carpeta={'anexos'}
                        tipos={['pdf', 'png', 'jpg']}
                    />
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Boletin;