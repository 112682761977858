/**************************************************
 * Nombre:       Checking
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, ButtonBase, Grid, InputAdornment, MenuItem, TextField, Typography, useMediaQuery} from "@mui/material";
import {ClipboardExport, DocumentCloud, Filter, SearchNormal1} from "iconsax-react";
import {theme} from "../../Tema";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import Tarjeta_checking_Dash from "../Tarjetas/Tarjeta_checking_Dash";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Checking_Nuevo from "../Dialogos/Dialogo_Checking_Nuevo";
import {ACENTO} from "../../Constantes";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, orderBy, query, where} from "firebase/firestore";
import {fire} from "../../fire";


const Checking = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [wheres, setWheres] = useState([])
    const {data, cargarMas, setData} = useRecuperarColleccionListeningProg({
        col: 'checkings',
        wheres: wheres,
        direccion: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Checking_Nuevo})
    const [palabra, setPalabra] = useState('')
    const [estado, setEstado] = useState('fecha')


    const buscar = () => {

        if (palabra !== '') {

            let placa = palabra.replaceAll(' ', "").toUpperCase()

            let col = collection(fire, 'checkings')
            let q = query(col, where('placa', '==', placa), orderBy('fecha', 'desc'))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res && dox.data.length > 0) {
                    setData(dox.data)
                } else {
                    alert('No hay registros con esta placa ' + placa)
                }
            })
        }
    }


    useEffect(() => {

        if (estado === 'fecha') {
            setWheres([])
        } else {
            setWheres([where('estado', '==', estado)])
        }


    }, [estado])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{py: 4, px: 4,}}
        >

            <Dialogo/>

            <Grid item lg={2} sm={3} xs={4}>
                <Typography sx={{fontSize: masSM ? 28 : 22, fontWeight: 800}}>{'Cheking'}</Typography>
            </Grid>

            <Grid item lg={3} sm={4} xs={8}>
                <TextField
                    label={'Buscar por placa'}
                    value={palabra}
                    size={'small'}
                    onChange={(e) => setPalabra(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ButtonBase
                                    onClick={() => buscar()}
                                    sx={{backgroundColor: ACENTO, p: 0.6, borderRadius: 2, boxShadow: 2}}>
                                    <SearchNormal1 color={'#fff'} variant={"Bold"}/>
                                </ButtonBase>

                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                />
            </Grid>

            <Grid item container lg={7} sm={5} xs={12} sx={{justifyContent: "flex-end", marginTop: sCell ? 2 : 0}}>
                <Button
                    onClick={() => abrir()}
                    startIcon={<ClipboardExport variant={'Bold'}/>}
                >
                    Nuevo Checking
                </Button>
            </Grid>


            <Grid item container lg={2} sm={3} xs={12} sx={{justifyContent: "flex-end", marginTop: sCell ? 2 : 4}}>
                <TextField
                    select
                    label={'Filtrar por: '}
                    value={estado}
                    size={'small'}
                    onChange={(e) => setEstado(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Filter color={'#00000050'} variant={"Bold"}/>
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                >
                    {estados.map((it, index) => {
                        return (
                            <MenuItem value={it} key={index}>{it}</MenuItem>
                        )
                    })}
                </TextField>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_checking_Dash} data={data} click={abrir}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4, marginBottom: 4}}>
                <Button
                    onClick={() => cargarMas()}
                    sx={{color: ACENTO}}
                    variant={'outlined'}>Cargar Mas</Button>
            </Grid>

        </Grid>
    )
}

export default Checking;

const estados = ['fecha', 'creado', 'diligenciado', 'aprobado']