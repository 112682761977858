/**************************************************
 * Nombre:       Compartir
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {ACENTO, LINKMAIL, URLDEFAULT} from "../../../Constantes";
import {Facebook, Sms, Whatsapp} from "iconsax-react";
import {useLocation} from "react-router-dom";


const Compartir = () => {
    const location = useLocation()
    const [sec, setSec] = useState('Boletines/nn')


    const redescom = [
        {
            nombre: 'facebook',
            url: 'http://www.facebook.com/sharer.php?u=' + URLDEFAULT + sec,
            Icono: Facebook
        },
        {
            nombre: 'was',
            url: 'whatsapp://send?text=Esta%20noticia%20me%20parecio%20interesante%20' + URLDEFAULT + sec,
            Icono: Whatsapp
        },
        {
            nombre: 'correo',
            url: 'mailto:?subject=Asunto del mensaje&body=Esta noticia me parecio interesante ' + URLDEFAULT + sec,
            Icono: Sms
        },
    ]


    useEffect(() => {
        setSec(location.pathname)
    }, [location])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12}>
                <Typography sx={{color: ACENTO, fontSize: 16, fontWeight: 600}}>Compartir</Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {redescom.map((it, index) => {
                        return (
                            <Grid item lg={1} sm={2} xs={2}>
                                <ButtonBase
                                    target="_blank" href={it.url}
                                    sx={{backgroundColor: ACENTO, p: 1, borderRadius: 2, boxShadow: 2}}>
                                    <it.Icono color={'#fff'} variant={'Bold'}/>
                                </ButtonBase>

                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>

        </Grid>
    )
}

export default Compartir;

