// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// ternationalmd.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyDlK8MUiUZKAc5n3KVg_u5EffFwZX7lQ14",
    authDomain: "pagina-web-a51b5.firebaseapp.com",
    projectId: "pagina-web-a51b5",
    storageBucket: "pagina-web-a51b5.appspot.com",
    messagingSenderId: "78813827589",
    appId: "1:78813827589:web:5fd65dc12191e05c49026c",
    measurementId: "G-Z9FBBXL05L"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)