/**************************************************
 * Nombre:       Boletines
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {ArchiveBook} from "iconsax-react";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Boletines from "../Dialogos/Dialogo_Boletines";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Boletines from "../Tarjetas/Tarjeta_Boletines";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";


const Boletines = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Boletines})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'boletines', wheres: wheres, direccion: 'desc'})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >
            <Dialogo/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                <Button
                    onClick={() => abrir()}
                    startIcon={<ArchiveBook variant={'Bold'}/>}>Nuevo Boletin</Button>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Ho_ManejoTargetas Card={Tarjeta_Boletines} data={data} click={abrir}/>

                </Grid>
            </Grid>

        </Grid>
    )
}

export default Boletines;