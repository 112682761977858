/**************************************************
 * Nombre:       Formulario_Cheking
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {DividerTexto, FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {
    Barcode,
    BinanceCoin,
    Bucket, Cake, Calendar,
    Call,
    CardTick1, Health, Location, Lock,
    Personalcard, SecuritySafe, SecurityUser, Tag,
    Truck, TruckFast,
    User, UserTick,
    WeightMeter,
    Whatsapp
} from "iconsax-react";
import {CARROSERIAS, TIPOS} from "../../Constantes";


const Formulario_Cheking = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Nombres y Apellidos'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Personalcard} label={'Cc:'} dato={'cc'} type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Call} label={'Celular'} dato={'celular'} type={'phone'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={CardTick1} label={'Licencia o Pase #'} dato={'pase'} type={'number'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Planilla Pago Seguridad Social'} sm={8} xs={8} lg={8}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Health} label={'EPS'} dato={'eps'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={SecuritySafe} label={'ARP'} dato={'arp'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconFecha label={'Vigente Hasta'} dato={'vigente'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos Vehiculo'} sm={6} xs={4} lg={4}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Tipo'} dato={'tipo'} opciones={TIPOS}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Numero Ejes'} dato={'ejes'} opciones={[2, 3, 4]}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Carroceria'} dato={'carroseria'}
                                              opciones={CARROSERIAS}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={BinanceCoin} label={'Marca'} dato={'marca'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Barcode} label={'Placa'} editable={false} dato={'placa'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={WeightMeter} label={'Capacidad en KG'} dato={'tara'}
                                        type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Bucket} label={'Color'} dato={'color'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Tag} label={'Linea'} dato={'modelo'}/>

                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Cake} label={'Año'} dato={'year'} type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={TruckFast} label={'# de Motor'} dato={'motor'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Truck} label={'# de Chasis'} dato={'chasis'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos Gps del vehiculo'} sm={6} xs={4} lg={4}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Location} label={'Empresa satelital'} dato={'link'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={SecurityUser} label={'Cuenta (si aplica)'} dato={'cuenta'}/>
                </Grid>


                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={UserTick} label={'Usuario'} dato={'usuario'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Lock} label={'Clave de ingreso'} dato={'clave'}/>
                </Grid>

            </Grid>
        </FormBase>
    )
}

export default Formulario_Cheking;
