export const verificarPermiso = () => {

    return new Promise(resolve => {

        try {
            navigator.serviceWorker.register('/firebase-messaging-sw.js');
            Notification.requestPermission(function (result) {
                if (result !== 'granted') {
                    return resolve({res: false, data: 'Notificaciones inactivas'})
                } else {
                    return resolve({res: true, data: null})
                }
            });
        } catch (e) {
            return resolve({res: true, data: null})
        }

    })
}