/**************************************************
 * Nombre:       BarrasMenu
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import BarraSuperior from "./BarraSuperior";
import Menu from "./Menu";


const BarrasMenu = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                <BarraSuperior/>
            </Grid>

            <Grid item container lg={12} sm={10} xs={12} sx={{justifyContent: "flex-end", marginTop: 1}}>
                <Menu/>
            </Grid>

        </Grid>
    )
}

export default BarrasMenu;