/**************************************************
 * Nombre:       Dialogo_Checking_Nuevo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formuario_Checking_Nuevo from "../Fomularios/Formuario_Checking_Nuevo";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";


const Dialogo_Checking_Nuevo = ({cerrar, id}) => {
    const {Formulario, props, entidad, setEntidad} = useFormulario({
        Form: Formuario_Checking_Nuevo,
        nombre: "checkingNuevo"
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        if (entidad.placa === undefined || entidad.fecha === undefined) {
            alert('El campo placa y fecha sone obligatorios')
            return
        }

        setCargando(true)

        let obj = entidad

        obj.estado = 'creado'
        obj.placa = entidad.placa.toUpperCase().replaceAll(" ", "")

        guardarDoc('checkings', entidad).then((dox) => {
            if (dox.res) {
                alert('Checking creado con exito')
                cerrar()
            } else {
                alert('Algo indesperado paso, por favor intentalo nuevamente')
            }
            setCargando(false)
        })


    }


    const borrar = () => {
        borrarDoc('checkings', entidad.id).then((dox) => {
            alert('Chequeo borrado con exito, refresque la pagina para ver los cambios')
            setEntidad({})
            cerrar()
        })
    }

    useEffect(() => {
        if (id) {
            obtenerDoc('checkings', id.id).then((dox) => {
                if (dox.res && dox.data) {
                    setEntidad(dox.data)
                } else {
                    setEntidad({})
                }
            })
        }
    }, [id])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12}>
                <Formulario  {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <LoadingButton
                    onClick={() => guardar()}
                    loading={cargando}
                    variant={'contained'}>
                    Crear Checking
                </LoadingButton>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                {entidad && entidad.id &&
                <Button
                    onClick={() => borrar()}
                    variant={'Text'}
                    sx={{color: '#00000050', px: 0}}
                >
                    Borrar Chequeo
                </Button>
                }
            </Grid>


        </Grid>
    )
}

export default Dialogo_Checking_Nuevo;