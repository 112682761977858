/**************************************************
 * Nombre:       NoticiasAnteriores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import NoticiaImagenTituloPeq from "./NoticiaImagenTituloPeq";
import {theme} from "../../../Tema";


const NoticiasAnteriores = ({data}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    const [items, setItems] = useState([])


    useEffect(() => {
        let arr = []

        for (let i = 6; i < data.length; i++) {
            arr.push(data[i])
        }

        setItems(arr)

    }, [data])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >


            <Grid item lg={12} sm={12} xs={12} sx={{height: 10, borderTop: 2, marginX: 4}}>

            </Grid>

            <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item container lg={12} sm={12} xs={12} sx={{
                        marginTop: 4, justifyContent: masSM ? 'flex-start' : 'center'
                    }}>
                        <Typography sx={{fontFamily: 'Montserrat', fontWeight: 800, fontSize: 26}}>Noticias
                            Anteriores</Typography>
                    </Grid>


                    <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 4}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={8}
                            sx={{px: masSM ? 0 : 3}}
                        >

                            {items.map((it, index) => {
                                return (
                                    <Grid item lg={4} sm={6} xs={12}>
                                        <NoticiaImagenTituloPeq item={it}/>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )
}

export default NoticiasAnteriores;

