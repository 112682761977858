/**************************************************
 * Nombre:       Boletines
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, Grid, useMediaQuery} from "@mui/material";
import NoticiaActual from "./ComponentesBoletines/NoticiaActual";
import BarraNoticias from "./ComponentesBoletines/BarraNoticias";
import NoticiasRecientes from "./ComponentesBoletines/NoticiasRecientes";
import NoticiasAnteriores from "./ComponentesBoletines/NoticiasAnteriores";
import Contactos from "../SubSecciones/Contactos";
import Contactanos from "./ComponentesBoletines/Contactanos";
import FooterNoticias from "../FooterNoticias";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {useParams} from "react-router-dom";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {irArriba} from "../../Utilidades/irArriba";
import {theme} from "../../Tema";


const Boletines = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'boletines',
        wheres: wheres,
        direccion: 'desc'
    })
    const parms = useParams()
    const [principal, setPrincipal] = useState({})


    useEffect(() => {

        irArriba()
        if (parms.id && parms.id !== 'nn') {
            obtenerDoc('boletines', parms.id).then((dox) => {
                if (dox.res) {
                    setPrincipal(dox.data)
                } else {
                    setPrincipal(data[0])
                }
            })

        } else {
            setPrincipal(data[0])
        }
    }, [parms, data])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: sCell ? 1 : 0}}
        >




            <h1 style={{display: 'none'}}>Transporte en Colombia</h1>

            <h2 style={{display: 'none'}}>Vias colombianas</h2>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <BarraNoticias/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <NoticiaActual item={principal}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                <NoticiasRecientes data={data}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <NoticiasAnteriores data={data}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <Contactanos/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                <FooterNoticias/>
            </Grid>


        </Grid>
    )
}

export default Boletines;