/**************************************************
 * Nombre:       Menu
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {useNavigate} from 'react-router-dom'


const Menu = () => {
    const [x, setX] = useState("Inicio")
    const navigate = useNavigate()


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
        >

            {secciones.map((item, index) => {
                return (
                    <Grid
                        component={"div"}
                        data-aos="fade-right"
                        data-aos-delay={200 * index}
                        data-aos-duration="1500"
                        key={index}
                        item container lg={1.5} sm={2} xs={12} sx={{justifyContent: "center"}}>
                        <ButtonBase
                            href={"#" + item}

                            sx={{borderBottom: x === item ? 2 : 0, borderColor: "#FF6623", py: 0.5}}>
                            <Typography
                                onClick={() => setX(item)}
                                sx={{
                                    color: x === item ? "#fff" : "#ffffff80",
                                    fontFamily: "Montserrat",
                                    fontSize: 16,
                                    fontWeight: 700,
                                    "&:hover": {color: "#fff"},

                                }}>{item}</Typography>
                        </ButtonBase>
                    </Grid>
                )
            })}


            <Grid
                component={"div"}
                data-aos="fade-right"
                data-aos-delay={350}
                data-aos-duration="1500"
                item container lg={1.5} sm={2} xs={12} sx={{justifyContent: "center"}}>
                <ButtonBase
                    onClick={() => navigate('/Boletines/nn')}
                    sx={{py: 0.5}}>
                    <Typography
                        sx={{
                            color: '#fff',
                            fontFamily: "Montserrat",
                            fontSize: 16,
                            fontWeight: 700,
                            "&:hover": {color: "#fff"},

                        }}>Boletines</Typography>
                </ButtonBase>
            </Grid>


        </Grid>
    );
}

export default Menu;

const secciones = ["Inicio", "Clientes", "Testimonios", "Contactos", "Equipo", "Nosotros"]