/**************************************************
 * Nombre:       Checking
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {createContext, React, useEffect, useRef, useState} from 'react';
import {Button, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {useParams, useNavigate} from 'react-router-dom'
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Cheking from "../Formularios/Formulario_Cheking";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Cheking from "../Dialogos/Dialogo_Cheking";
import Tarjeta_Checking from "../Tarjetas/Tarjeta_Checking";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import {LoadingButton} from "@mui/lab";
import frenteImg from '../../Recursos/camion_frente.png'
import interiorImg from '../../Recursos/camion_interior.png'
import cargaImg from '../../Recursos/unidadCarga.png'
import sisternaImg from '../../Recursos/sisterna.png'
import SignatureCanvas from 'react-signature-canvas'
import {ACENTO} from "../../Constantes";
import {Helmet} from 'react-helmet'
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {UserOctagon} from "iconsax-react";

export const contextoCheking = createContext()

const Checking = () => {
    const parms = useParams()
    const navigate = useNavigate()
    const [placa, setPlaca] = useState('')
    const {Formulario, props, entidad, setEntidad} = useFormulario({Form: Formulario_Cheking, nombre: 'checking'})
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Cheking})
    const [cargando, setCargando] = useState(false)
    const [datosListas, setDatosListas] = useState([])
    const [firma, setFirma] = useState('')

    const [lista, setLista] = useState([])
    const sigRef = useRef();


    const {Provider} = contextoCheking

    const manejoFirma = () => {
        setFirma(sigRef.current.toDataURL());
    }


    const guardar = () => {


        if (firma === undefined) {
            alert('La firma es requerida')
            return
        }

        setCargando(true)

        //falta saber como necesitan ver lista de chekeo
        let obj = entidad
        obj.fechaDiligenciado = new Date().getTime()
        obj.estado = 'diligenciado'
        obj.firma = firma
        obj.datos = datosListas


        guardarDoc('checkings', obj).then((dox) => {
            if (dox.res) {
                alert('Cheking realizado con exito, sera redirigido a la pagina principal')
                navigate('/Home')
            } else {
                alert('Algo inesperado paso, por favor intentelo nuevamente')
            }
            setCargando(false)
        })


    }

    useEffect(() => {
        if (parms && parms.id) {
            setPlaca(parms.id)

            let ref = collection(fire, 'checkings')
            let q = query(ref, where('placa', '==', parms.id.toUpperCase()), where('estado', '==', 'creado'))

            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res && dox.data && dox.data[0]) {
                    let data = dox.data[0]
                    setEntidad(data)
                    setLista([])
                    if (data.unidad) {
                        setLista((e) => e.concat(frente))
                    }
                    if (data.furgon) {
                        setLista((e) => e.concat(furgon))
                    }
                    if (data.cisterna) {
                        setLista((e) => e.concat(cisterna))
                    }
                    if (data.plataforma) {
                        setLista((e) => e.concat(plataforma))
                    }
                    if (data.camion) {
                        setLista((e) => e.concat(camion))
                    }
                    if (data.interior) {
                        setLista((e) => e.concat(interior))
                    }

                    setFirma(data.firma)


                    /******llenado de listas *******/

                    if (data.datos) {
                        setDatosListas(data.datos)
                    }


                } else {
                    alert('Esta lista de chequeo no esta disponible')
                    navigate('/Home')
                }
            })
        }

    }, [parms])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Helmet>
                <title>Checking Vehiculo </title>
                <meta property="og:title" content={"Checking Vehiculo Placa " + placa}/>
                <meta property="og:image" content="%PUBLIC_URL%/international_logistics_md.png"/>
            </Helmet>


            <Provider value={{
                datosListas: datosListas,
                setDatosListas: setDatosListas
            }}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >
                    <Grid item lg={4} sm={4} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >


                            <Dialogo/>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                                <Typography sx={{fontSize: 28, fontWeight: 600}}>{'Lista de Chequeo'}</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: -1}}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 400
                                    }}>{'Placa del Vehiculo: ' + placa.toUpperCase()}</Typography>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                <Formulario {...props} />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"

                                >
                                    <Ho_ManejoTargetas Card={Tarjeta_Checking} spacing={2} click={abrir} data={lista}
                                                       xs={6} lg={6} sm={6}/>

                                </Grid>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                <Typography sx={{textAlign: 'justify'}}>Yo <span
                                    style={{marginRight: 5, fontWeight: 600}}>{entidad && entidad.nombre}</span>
                                    identificado con cedula de ciudadania #<span
                                        style={{marginRight: 5, fontWeight: 600}}>{entidad && entidad.cc}</span>
                                    hago constar que toda la informacion suministrada en
                                    la presente lista de chequeo es veridica y que podra ser validada por el auxiliar
                                    logistico y/o despachador de INTERNATIONAL LOGISTICS MD SAS.</Typography>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                <Typography sx={{fontSize: 18, fontWeight: 600}}>Firma del conductor</Typography>
                            </Grid>

                            <Grid container item lg={12} sm={12} xs={12}
                                  sx={{border: 1, marginTop: 2, justifyContent: 'center'}}>
                                <SignatureCanvas
                                    ref={sigRef}
                                    onEnd={manejoFirma}
                                    penColor='black'
                                    canvasProps={{width: 355, height: 150}}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                                <Button
                                    onClick={() => sigRef.current.clear()}
                                    variant={'outlined'}
                                    sx={{color: ACENTO}}

                                >limpiar</Button>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: 4, marginBottom: 6}}>
                                <LoadingButton
                                    variant={'contained'}
                                    loading={cargando}
                                    onClick={() => guardar()}
                                >
                                    Enviar Lista
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Provider>

        </Grid>
    )
}

export default Checking;

const frente = {
    id: 'unidad',
    img: frenteImg,
    nombre: 'Unidad de Carga',
    preguntas:
        [
            'Estado lado izquierdo',
            'Estado lado derecho',
            'Estado planchon y/o piso',
            'Estado Techo',
            'Puertas o compuertas',
            'Seguros y bloqueo de puertas',
            'Exterior y bastidor',
            'Sistema de refrigeracion y compartiminetos accesibles',
            'Latonería',
            'Exterior de la cabina',
            'Interior de la cabina',
            'Tanque de combustible, agua, aire',
            'Estado de los Vidrios',
            'Estado de los Espejos',
            'Estado del Pito',
            'Estado de los laterales internos',
            'Estado de laterales externos',
            'Caja herramientas',
            'Caja de batería',
            'Llantas de trailer',
        ]
}

const furgon = {
    id: 'furgon',
    img: cargaImg,
    nombre: 'Para Furgon',
    preguntas:
        [
            'Estado lado izquierdo',
            'Estado lado derecho',
            'Estado planchon y/o piso',
            'Estado Techo',
            'Puertas o compuertas',
            'Seguros y bloqueo de puertas',
            'Sistema de refrigeracion',
            'Exterior y bastidor',
        ]
}

const cisterna = {
    id: 'cisterna',
    img: sisternaImg,
    nombre: 'Para Cisterna',
    preguntas:
        [
            'Latoneria',
            'Exterior de laminas',
            'Interior del tanque',
            'Compartimientos',
            'Cortinas',
            'Valvula de descargue',
            'Empaques',
            'Lllanta de emegencia',
            'Pasarela',
            'Escalera',
            'Serpentin',
            'Kit de derrames',
            'Guardabarros',
            'Llanta de repuesto',
        ]
}

const plataforma = {
    id: 'plataforma',
    img: cargaImg,
    nombre: 'Para plataformas chasis y similares',
    preguntas:
        [
            'Pata mecanica',
            'Llantas',
            'Parachoques',
            'Luces',
            'Placa de patín / quinta Rueda',
            'Puntos de anclaje (4 pines)o seguro del trailer al contenedor',
            'Sistema de refrigeracion si aplica',
        ]
}

const camion = {
    id: 'camiones',
    img: frenteImg,
    nombre: 'Para Camiones ( tractores / cabezales)',
    preguntas:
        [
            'Parachoques',
            'Luces',
            'Aros o rines',
            'Puertas',
            'Compartimientos de herrmientas',
            'Seguros y bloqueos (mecanismo de bloqueo)',
            'Filtro de aire',
            'Tanques de combustible y agua',
            'Compartimientodelinterior ypisodelacabinaylitera',
            'Seccion de pasajeros y techo de la cabina',
        ]
}

const interior = {
    id: 'interior',
    img: interiorImg,
    nombre: 'Coplementarios',
    preguntas:
        [
            'Velocímetro.',
            'Tacómetro',
            'Indicadores de temperatura de motor, aceite, cargue batería, freno de mano y gasolina.',
            'Luces Altas.',
            'Luces Bajas.',
            'Cocuyos Delanteros.',
            'Cocuyos Traseros.',
            'Luces de estacionamiento.',
            'Direccionales delanteros.',
            'Direccionales traseros.',
            'Luces de reversa.',
            'Pare.',
            'Luces del Tráiler',
            'Sistema de prevencion y seguridad',
            'Cinturones de seguridad.',
            'Cinchas y manilas',
            'Cuñas y/o tacos.',
            'Casco, chaleco y botas',
            'Juego de Herramientas.',
            'Señales en forma de triangulo.Señales en forma de triangulo.',
            'Botiquín Primeros Auxilios.',
            'Extintor de Incendios',
            'Linterna.',
            'Aseo General del Vehículo',
            'Sistema de dirección.',
            'Sistema de suspensión.',
            'Llantas delanteras del cabezote.',
            'Llantas traseras del cabezote.',
            'Llantas del tráiler.',
            'Llantas de Repuesto.',
            'Sistema de frenos',
            'Bandas',
            'Líquidos de frenos.',
        ]
}


