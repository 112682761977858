/**************************************************
 * Nombre:       CuerpoNoticia
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {ACENTO} from "../../../Constantes";
import punto from "../../../Recursos/punto.svg";
import {getFechaTimestamp} from "../../../Utilidades/getFechaTimestamp";
import {getFechaTimestampSencillo} from "../../../Utilidades/getFechaTimestampSencillo";


const CuerpoNoticia = ({item}) => {
    const [items, setItems] = useState([])


    useEffect(() => {

        let claves = ['uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis']
        let arr = []

        for (let i = 0; i < claves.length; i++) {

            if (item[claves[i]]) {
                arr.push(item[claves[i]])
            }
        }

        setItems(arr)

    }, [item])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Typography
                    sx={{color: ACENTO, fontSize: 14, fontWeight: 500, fontFamily: 'Montserrat'}}>
                    {item.fecha && getFechaTimestampSencillo(item.fecha)}
                </Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Typography sx={{fontSize: 30, fontWeight: 800, fontFamily: 'Montserrat', lineHeight: 1.1}}>
                    {item.titulo}
                </Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Typography sx={{fontSize: 18, fontWeight: 400}}>
                    {item.resumen}
                </Typography>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {items.map((it) => {
                        return (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{marginTop: 2}}
                            >

                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: "center", marginTop: 1}}>
                                    <img src={punto} width={10} height={'auto'} title={'punto'}
                                         alt={'punto'}/>
                                </Grid>

                                <Grid item lg={11} sm={11} xs={11}>
                                    <Typography sx={{fontSize: 16, fontWeight: 400}}>
                                        {it}
                                    </Typography>
                                </Grid>

                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>

        </Grid>
    )
}

export default CuerpoNoticia;

