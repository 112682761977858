import {getToken} from "firebase/messaging";
import {getMessaging} from "firebase/messaging/sw";
import {CERTIFICADOPUSH} from "../Constantes";
import {app} from "../../fire";

export const obtenerToken = () => {

    return new Promise(resolve => {
        // se obtiene permiso de notificaciones

        try {
            navigator.serviceWorker.register('/firebase-messaging-sw.js');
            Notification.requestPermission(function (result) {
                if (result !== 'granted') {
                    alert("Las Notificaciones no estan activas")
                    return resolve({res: false, data: 'Notificaciones inactivas'})
                }
            });
        } catch (e) {
            return resolve({res: false, data: 'Error en permisos'})
        }


        const messaging = getMessaging(app);

        getToken(messaging, {vapidKey: CERTIFICADOPUSH,}).then((currentToken) => {
            if (currentToken) {
                return resolve({res: true, data: currentToken})
            } else {
                return resolve({res: false, data: 'El servidor no logro generar un token'})
            }
        }).catch((err) => {
            return resolve({res: false, data: err.message})
        });


    })


}