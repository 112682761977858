/**************************************************
 * Nombre:       Formuario_Checking_Nuevo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Collapse, Grid, InputAdornment, TextField} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {
    ArrowDown2, ArrowUp2,
    Barcode,
    BinanceCoin, Bucket, Cake,
    Call,
    CardTick1,
    Health, Location, Lock,
    Personalcard,
    SecuritySafe, SecurityUser, Tag,
    Truck, TruckFast, TruckTick,
    User, UserTick,
    WeightMeter
} from "iconsax-react";
import {ACENTO, CARROSERIAS, TIPOS} from "../../Constantes";
import {LoadingButton} from "@mui/lab";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, limit, orderBy, query, where} from "firebase/firestore";
import {fire} from "../../fire";


const Formuario_Checking_Nuevo = ({...props}) => {
    const [open, setOpen] = useState(false)
    const [placa, setPlaca] = useState('')
    const [cargando, setCargando] = useState(false)

    const obtenerDatos = () => {

        if (!placa) {
            alert('Placa no debe estar vacia')
            return
        }

        setCargando(true)
        let col = collection(fire, 'checkings')
        let q = query(col, where('placa', '==', placa), limit(1), orderBy('fecha', 'desc'))

        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res && dox.data.length > 0) {
                let obj = dox.data[0]
                delete obj.datos
                delete obj.fechaDiligenciado
                delete obj.firma
                delete obj.id
                delete obj.fecha
                delete obj.vigente
                props.setEntidad(obj)
            }
            setCargando(false)
        })

    }


    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <Button
                        onClick={() => setOpen(!open)}
                        startIcon={open ? <ArrowUp2 variant={'Bold'} size={'1rem'}/> :
                            <ArrowDown2 variant={'Bold'} size={'1rem'}/>}
                        sx={{px: 2}}
                        size={'small'} color={'secondary'}>Copiar Datos</Button>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Collapse in={open} sx={{width: '100%'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >

                            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TruckTick color={ACENTO} variant={"Bold"}
                                                />
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true
                                    }}
                                    value={placa}
                                    onChange={(e) => setPlaca(e.target.value)}
                                    size={"small"}
                                    variant={'outlined'}
                                    label={'Placa del vehiculo a copiar'}/>
                            </Grid>

                            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                                <LoadingButton
                                    loading={cargando}
                                    onClick={() => obtenerDatos()}
                                    variant={'contained'}
                                >
                                    Copiar datos
                                </LoadingButton>
                            </Grid>

                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos del vehiculo (obligatorio)'} lg={7}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Barcode} label={'Placa'} dato={'placa'} hint={'Ejm: ABC123'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconFecha Icono={Barcode} label={'Fecha de Creacion'} dato={'fecha'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos del conductor (opcional)'} lg={7}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Nombres y Apellidos'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Personalcard} label={'Cc:'} dato={'cc'} type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Call} label={'Celular'} dato={'celular'} type={'phone'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={CardTick1} label={'Licencia o Pase #'} dato={'pase'} type={'number'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Planilla Pago Seguridad Social'} sm={8} xs={8} lg={8}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Health} label={'EPS'} dato={'eps'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={SecuritySafe} label={'ARP'} dato={'arp'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconFecha label={'Vigente Hasta'} dato={'vigente'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos Vehiculo'} sm={6} xs={4} lg={4}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Tipo'} dato={'tipo'} opciones={TIPOS}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Numero Ejes'} dato={'ejes'} opciones={[2, 3, 4]}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIconSelect Icono={Truck} label={'Carroceria'} dato={'carroseria'}
                                              opciones={CARROSERIAS}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={BinanceCoin} label={'Marca'} dato={'marca'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Barcode} label={'Placa'} editable={false} dato={'placa'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={WeightMeter} label={'Capacidad en KG'} dato={'tara'}
                                        type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Bucket} label={'Color'} dato={'color'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Tag} label={'Linea'} dato={'modelo'}/>

                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Cake} label={'Año'} dato={'year'} type={'number'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={TruckFast} label={'# de Motor'} dato={'motor'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Truck} label={'# de Chasis'} dato={'chasis'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Datos Gps del vehiculo'} sm={6} xs={4} lg={6}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Location} label={'Empresa satelital'} dato={'link'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={SecurityUser} label={'Cuenta (si aplica)'} dato={'cuenta'}/>
                </Grid>


                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={UserTick} label={'Usuario'} dato={'usuario'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon Icono={Lock} label={'Clave de ingreso'} dato={'clave'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.DividerTexto texto={'Opciones de Chequeo'} lg={5}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputCheck dato={'unidad'} label={'Unidad Carga'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                    <FormBase.InputCheck dato={'furgon'} label={'Para Furgon'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                    <FormBase.InputCheck dato={'cisterna'} label={'Para Cisterna'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                    <FormBase.InputCheck dato={'plataforma'} label={'Para Plataforma Chais y Similares'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                    <FormBase.InputCheck dato={'camion'} label={'Para Camiones (tractores / cabezales)'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                    <FormBase.InputCheck dato={'interior'} label={'Complementarios'}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formuario_Checking_Nuevo;