/**************************************************
 * Nombre:       Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, useMediaQuery} from "@mui/material";
import {Buildings2, Call, Sms, Whatsapp} from "iconsax-react";
import {irUrl} from "../../Utilidades/irUrl";
import {LINKCAL, LINKMAP} from "../../Constantes";
import {llevarCorreo} from "../../Utilidades/llevarCorreo";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {theme} from "../../Tema";


const Contactos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: "#E0E0E0"}}
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3, py: sCell ? 3 : 0}}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{m: sCell ? 0 : 8}}
                >
                    <Grid item lg={5} sm={12} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{backgroundColor: "#000", px: sCell ? 0 : 4, height: 370}}
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid
                                        component={"div"}
                                        data-aos={sCell ? "fade-up" :"fade-left"}
                                        data-aos-delay={100}
                                        data-aos-duration="1500"
                                        item lg={12} sm={12} xs={12}>
                                        <Button
                                            onClick={() => irUrl(LINKMAP)}
                                            startIcon={<Buildings2 color={"#fff"} variant={"Bulk"}/>}
                                            variant={"text"} sx={{color: "#fff", fontSize: sCell ? 16 : 18, fontWeight: 500}}>Ipiales
                                            Cra 1a
                                            No. 12-09 Barrio victoria
                                            norte</Button>
                                    </Grid>

                                    <Grid
                                        component={"div"}
                                        data-aos={sCell ? "fade-up" :"fade-left"}
                                        data-aos-delay={150}
                                        data-aos-duration="1500"
                                        item lg={12} sm={12} xs={12}>
                                        <Button
                                            onClick={() => llevarCorreo({correo: "gcomercial@internationalmdsas.com"})}
                                            startIcon={<Sms color={"#fff"} variant={"Bulk"}/>}
                                            variant={"text"} sx={{color: "#fff", fontSize: sCell ? 16 : 18, fontWeight: 500}}>
                                            gcomercial@internationalmdsas.com
                                        </Button>
                                    </Grid>

                                    <Grid
                                        component={"div"}
                                        data-aos={sCell ? "fade-up" :"fade-left"}
                                        data-aos-delay={200}
                                        data-aos-duration="1500"
                                        item lg={12} sm={12} xs={12}>
                                        <Button
                                            onClick={() => llevarCorreo({correo: "gerenciacolmd@gmail.com"})}
                                            startIcon={<Sms color={"#fff"} variant={"Bulk"}/>}
                                            variant={"text"} sx={{color: "#fff", fontSize: sCell ? 16 : 18, fontWeight: 500}}>
                                            gerenciacolmd@gmail.com
                                        </Button>
                                    </Grid>

                                    <Grid
                                        component={"div"}
                                        data-aos={sCell ? "fade-up" :"fade-left"}
                                        data-aos-delay={250}
                                        data-aos-duration="1500"
                                        item lg={6} sm={12} xs={12}>
                                        <Button
                                            onClick={() => iniciarChatWhatsApp({
                                                num: "3164738993",
                                                tema: "Hola, deseo mas informacion"
                                            })}
                                            startIcon={<Whatsapp color={"#fff"} variant={"Bulk"}/>}
                                            variant={"text"} sx={{color: "#fff", fontSize: sCell ? 16 : 18, fontWeight: 500}}>
                                            3164738993
                                        </Button>
                                    </Grid>

                                    <Grid
                                        component={"div"}
                                        data-aos={sCell ? "fade-up" :"fade-left"}
                                        data-aos-delay={300}
                                        data-aos-duration="1500"
                                        item lg={6} sm={12} xs={12}>
                                        <Button
                                            onClick={() => iniciarChatWhatsApp({
                                                num: "3183516582",
                                                tema: "Hola, deseo mas informacion"
                                            })}
                                            startIcon={<Whatsapp color={"#fff"} variant={"Bulk"}/>}
                                            variant={"text"} sx={{color: "#fff", fontSize: sCell ? 16 : 18, fontWeight: 500}}>
                                            3183516582
                                        </Button>
                                    </Grid>

                                    <Grid
                                        component={"div"}
                                        data-aos={sCell ? "fade-up" : "fade-left"}
                                        data-aos-delay={350}
                                        data-aos-duration="1500"
                                        item lg={6} sm={12} xs={12}>
                                        <Button
                                            startIcon={<Call color={"#fff"} variant={"Bulk"}/>}
                                            variant={"text"} sx={{color: "#fff", fontSize: sCell ? 16 : 18, fontWeight: 500}}>
                                            7750450
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>

                    <Grid item lg={7} sm={12} xs={12}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.399954512445!2d-77.63803702504704!3d0.8297417991622177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e296b3f86fb7a23%3A0xdf70a7f32cbcb06c!2sLogistics%20International%20MD!5e0!3m2!1ses!2sco!4v1685373680595!5m2!1ses!2sco"
                            width={"100%"} height={"370"} loading={"lazy"}
                            style={{border: 0}}
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>
    )
}

export default Contactos;