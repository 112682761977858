/**************************************************
 * Nombre:       Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {ArchiveBook, DocumentCloud} from "iconsax-react";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Boletines from "../Tarjetas/Tarjeta_Boletines";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Boletines from "../Dialogos/Dialogo_Boletines";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {ACENTO} from "../../Constantes";
import Tarjeta_Potenciales from "../Tarjetas/Tarjeta_Potenciales";
import Dialogo_Potencial from "../Dialogos/Dialogo_Potencial";
import {collection, query, where} from "firebase/firestore";
import {exportarJsonToExcel} from "../../Modulo_Excel/Exportar/ExportarJsonToExcel";
import {fire} from "../../fire";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {LoadingButton} from "@mui/lab";
import Tarjeta_Pqrs from "../Tarjetas/Tarjeta_Pqrs";
import Dialogo_Pqrs from "../Dialogos/Dialogo_Pqrs";


const Pqrs_Seccion = ({estado}) => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Pqrs})
    const [wheres, setWheres] = useState([where('archivada', '==', "none")])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({col: 'pqrs', wheres: wheres,})
    const [cargando, setCargando] = useState(false)


    const exportar = () => {
        setCargando(true)
        let q = query(collection(fire, 'pqrs'))
        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res) {
                exportarJsonToExcel({data: dox.data, nombre: 'Pqrs ' + new Date().toString()})
            } else {
                alert('Ups, algo inesperado paso, por favor intentalo mas tarde')
            }
            setCargando(false)
        })


    }

    useEffect(() => {

        setWheres([where('archivada', '==', estado)])

    }, [estado])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{py: 4, px: 4}}
        >

            <Dialogo/>
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                <LoadingButton
                    onClick={() => exportar()}
                    loading={cargando} variant={'outlined'} sx={{color: "#FC783E"}}
                    startIcon={<DocumentCloud variant={'Bold'}/>}>Descargar
                    Datos</LoadingButton>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Pqrs} data={data} click={abrir}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: 2, mb: 2}}>
                <Button
                    onClick={() => cargarMas()}
                    variant={'outlined'} sx={{color: '#FC783E'}}>Cargar Mas</Button>
            </Grid>

        </Grid>
    )
}

export default Pqrs_Seccion;