/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";
import {funRecuperarContrasena} from "../../Servicios/Auth/funRecuperarContrasena";
import {LoadingButton} from "@mui/lab";
import Formulario_Login from "../Formularios/Formulario_Login";


const Dialogo_Login = () => {
    const {Formulario, props, entidad} = useFormulario({Form: Formulario_Login, nombre: "login",})
    const [cargando, setCargando] = useState(false)

    const ingresar = () => {

        setCargando(true)
        funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
            if (!dox.res) {
                alert(dox.data)
            } else {
                console.log("Ingreso a la plataforma")
            }
            setCargando(false)
        })

    }


    const recuperarContrasena = () => {
        setCargando(true)
        funRecuperarContrasena(entidad.correo).then((dox) => {
            if (dox.res) {
                alert("Se ha enviado las instrucciones al correo " + entidad.correo + ".")
            } else {
                alert(dox.data)
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <ButtonBase
                    onClick={() => recuperarContrasena()}
                >
                    <Typography sx={{fontWeight: 600, color: "#000"}}>Olvidé mi contraseña</Typography>
                </ButtonBase>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4, marginBottom: 2}}>
                <LoadingButton
                    onClick={() => ingresar()}
                    variant={"contained"}
                    loading={cargando}>ingresar</LoadingButton>
            </Grid>

        </Grid>
    )
}

export default Dialogo_Login;