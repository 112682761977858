/**************************************************
 * Nombre:       Detalle_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {theme} from "../../Tema";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import Formulario_Potencial from "../Fomularios/Formulario_Potencial";
import Formulario_Pqrs from "../Fomularios/Formulario_Pqrs";
import {ACENTO} from "../../Constantes";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";


const Dialogo_Pqrs = ({cerrar, id}) => {
    const {Formulario, props, entidad, limpiarEntidad, setEntidad} = useFormulario({
        Form: Formulario_Pqrs,
        nombre: 'comprador',
    })
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [cargando, setCargando] = useState(false)

    const guardar = () => {


        setCargando(true)

        guardarDoc('pqrs', entidad).then((dox) => {
            if (dox.res) {
                alert('Cambios guardados con exito')
                limpiarEntidad()
                cerrar()
            } else {
                alert('Ups, algo inesperado paso, por favor intentalo mas tarde')
            }
            setCargando(false)
        })
    }


    const archivar = () => {

        actualizarDoc('pqrs', entidad.id, {archivada: true}).then((dox) => {
            if (dox.res) {
                alert('Elemento Archivado')
                limpiarEntidad()
                cerrar()
            }
        })

    }

    const borrar = () => {

        borrarDoc('pqrs', entidad.id).then((dox) => {
            if (dox.res) {
                alert('Elemento Borrado')
                limpiarEntidad()
                cerrar()
            }
        })

    }

    useEffect(() => {
        if (id) {
            setEntidad(id)
        }
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Formulario {...props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mt: sCell ? 6 : 3, mb: 1}}>
                <LoadingButton
                    onClick={() => guardar()}
                    variant={'contained'}
                    loading={cargando}>
                    Guardar
                </LoadingButton>
            </Grid>

            <Grid item lg={6} sm={6} xs={6}>
                <Button
                    onClick={() => borrar()}
                    color={'success'} size={'small'} variant={'text'} sx={{color: "#00000050"}}>Borrar</Button>

            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                <Button
                    onClick={() => archivar()}
                    color={'success'} size={'small'} variant={'text'} sx={{color: ACENTO}}>Archivar</Button>

            </Grid>


        </Grid>
    )
}

export default Dialogo_Pqrs;