/**************************************************
 * Nombre:       Servicios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {TEXTOACENTO} from "../../Constantes";
import nacional from '../../Recursos/img_nacional.webp'
import inter from '../../Recursos/img_inter.webp'
import contenedores from '../../Recursos/img_contenedores.png'
import asesoria from '../../Recursos/img_asesoria.webp'
import logistica from '../../Recursos/img_logistica.webp'
import almacena from '../../Recursos/img_almacena.webp'
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import DetalleServicio from "../Dialogos/DetalleServicio";
import {theme} from "../../Tema";


const Servicios = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Dialogo, abrir} = useDialogo({Componente: DetalleServicio,})


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: sCell ? 3 : 6}}
            >

                <Grid item lg={12} sm={12} xs={12} sx={{marginBottom: 6}}>
                    <Typography sx={{
                        color: TEXTOACENTO,
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: 600
                    }}>Mas servicios</Typography>
                </Grid>

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={150}
                    data-aos-duration="1500"
                    item lg={4} sm={4} xs={12}>
                    <Typography
                        sx={{
                            fontSize: 28,
                            fontFamily: "Montserrat",
                            fontWeight: 800,
                            lineHeight: 1.2,
                            textAlign: sCell ? "center" : "left"
                        }}>Hacemos la labor <br/> completa</Typography>
                </Grid>

                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={200}
                    data-aos-duration="1500"
                    item lg={8} sm={8} xs={12}>
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            textAlign: sCell ? "center" : "right",
                            marginTop: sCell ? 2 : 0
                        }}>Somos el aliado estratégico que necesitas para garantizar la seguridad y seguimiento
                        personalizado de tu carga nacional e internacional.</Typography>
                </Grid>

                <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 12, px: sCell ? 2 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={8}
                    >

                        {servicios.map((item, index) => {
                            return (
                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={250 * index}
                                    data-aos-duration="1500"
                                    item lg={4} sm={6} xs={12} key={index}>
                                    <Grid
                                        onClick={() => abrir(item)}
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        sx={{
                                            borderColor: "#00000080",
                                            borderRadius: 1,
                                            boxShadow: 4,
                                            cursor: "pointer",
                                            '&:hover': {boxShadow: 12}
                                        }}
                                    >

                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={item.img} width={"100%"} height={"auto"} alt={item.nombre}
                                                 title={item.nombre}
                                                 style={{borderTopLeftRadius: 4, borderTopRightRadius: 4}}
                                                 loading={'lazy'}/>
                                        </Grid>

                                        <Grid item lg={12} sm={12} xs={12} sx={{px: 3, marginTop: 3}}>
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontFamily: "Montserrat",
                                                    fontWeight: 800,
                                                }}>
                                                {item.nombre}
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} sm={12} xs={12} sx={{p: 3}}>
                                            <Typography sx={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                                minHeight: sCell ? "auto" : 180

                                            }}>
                                                {item.descripcion}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Servicios;

const servicios = [
    {
        nombre: "Transporte de carga Nacional",
        descripcion: "La tranquilidad de su carga está en nuestras manos. Somos su socio confiable en transporte y logística.",
        img: nacional,
        items: [
            "Aseguramos que su carga llegue a tiempo y en perfectas condiciones, gracias a nuestro monitoreo en línea 24/7",
            "Nuestra flota de vehículos especializados y nuestro equipo de expertos en transporte de carga garantizan la seguridad y la eficiencia en el traslado de sus productos",
            ".En International Logistics MD S.A.S ofrecemos soluciones personalizadas de transporte de carga, adaptadas a las necesidades específicas de cada cliente, garantizando la satisfacción total en el servicio."
        ]
    },
    {
        nombre: "Transporte de carga Internacional",
        descripcion: "Con nosotros encontrará logística de vanguardia y atención personalizada en un solo lugar, siendo líderes en transporte y comercio internacional.",
        img: inter,
        items: [
            "En International Logistics MD S.A.S, nuestro equipo de expertos en logística y comercio internacional se encarga de optimizar sus procesos logísticos, para que usted se enfoque en lo que realmente." +
            "importa: su negocio.",
            "Gracias a nuestras alianzas estratégicas con agentes aduaneros, bodegas y operarios especializados, garantizamos la eficiencia y la agilidad en todos los procesos de comercio internacional.",
            "En International Logistics MD S.A.S, ofrecemos soluciones de logística personalizadas en transporte de carga y comercio internacional, para que nuestros clientes obtengan la satisfacción total en cada uno de nuestros servicios."
        ]

    },
    {
        nombre: "Seguimiento y Monitoreo Satelital",
        descripcion: "Servicio de seguimiento y monitoreo satelital te brinda información en tiempo real sobre tu mercancía, este servicio es preciso y confiable para brindarte tranquilidad y control en tiempo real",
        img: contenedores,
        items: []
    },
    {
        nombre: "Asesoría en Agenciamiénto Aduanero",
        descripcion: "Somos su socio estratégico para la importación y exportación de sus mercancías, garantizando su éxito",
        img: asesoria,
        items: [
            "En International Logistics MD S.A.S contamos con un equipo de expertos en agenciamiento aduanero que garantiza la eficiencia y la rapidez en todos los trámites de importación y exportación",
            "Ofrecemos soliciones personalizadas de agendamiento aduanero adaptadas a las necesidades específicas de cada cliente para garantizar las satisfacción total y la optimización de sus procesos de comercio internacional.",
            "Gracias a nuestras alianzas estratégicas con agentes aduaneros y operarios especializados, garantizamos la seguridad y la eficiencia en todos los procesos de agenciamiento aduanero en Intenational Logisitcs MS S.A.S."

        ]
    },
    {
        nombre: "Almacenamiento y Bodegaje",
        descripcion: "Combinamos seguridad, rapidez y atención al cliente para ofrecer la solución ideal en transporte y almacenamiento de carga",
        img: almacena,
        items: [
            ".En International Logistics MD S.A.S, nos aseguramos de que sus productos estén siempre seguros y protegidos gracias a nuestra vigilancia constante y almacenamiento de alta calidad.",
            "¿Buscas un espacio seguro para almacenar sus productos? En International Logistics MD S.A.S, ofrecemos soluciones personalizadas de bodegaje adaptadas a sus necesidades específicas.",
            "Nuestro servicio de almacenamiento y bodegaje integral le brinda la tranquilidad que necesitas, gracias a nuestras modernas instalaciones y vigilancia constante. En International Logistics MD S.A.S, sus productos siempre están en buenas manos."
        ]
    },
    {
        nombre: "Logística en el transporte de carga",
        descripcion: "Logística de vanguardia y atención personalizada en un solo lugar, siendo líderes en transporte y comercio internacional",
        img: logistica,
        items: [
            "¿Le preocupa la seguridad de sus productos durante su transporte? Nuestro seguro de mercancía le protege en todo momento, brindándote tranquilidad y confianza en cada envío. (100% de la mercancía está asegurada).",
            "Nuestro servicio de seguro de mercancía\n" +
            "le permite estar seguro de que sus productos están protegidos en caso de cualquier imprevisto durante el transporte. ¡Protege la carga, asegure sus envíos con nosotros!",
            "En International Logistics MS S.A.S, le ofrecemos un seguro de mercancía completo y personalizado para garantizar la seguridad y la protección de sus productos en todo momento, dándote la tranquilidad que necesita al enviar su mercancia."
        ]
    },

]