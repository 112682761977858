export const copiarTexto = (id) => {
    var codigoACopiar = document.getElementById(id);
    var seleccion = document.createRange();
    seleccion.selectNodeContents(codigoACopiar);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(seleccion);
    var res = document.execCommand('copy');
    window.getSelection().removeRange(seleccion);
    alert(codigoACopiar.textContent + ' copiado a portapapeles')
}
