/**************************************************
 * Nombre:       Testimonios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {TEXTOACENTO} from "../../Constantes";
import ItemsTestimonios from "./Componentes/ItemsTestimonios";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import {theme} from "../../Tema";


const Testimonios = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: sCell ? 3 : 6}}

            >


                <Grid item lg={12} sm={12} xs={12}>
                    <Typography sx={{
                        color: TEXTOACENTO,
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: 600
                    }}>Testimoinios</Typography>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{px: sCell ? 0 : 6, marginTop: sCell ? 4 : 8}}>
                    <Splide aria-label="My Favorite Images" style={{width: "100%"}}
                            options={{
                                autoplay: true,
                                speed: 3000,
                                rewind: true,
                                perPage: masSM ? 2 : 1,
                                arrows: sCell ? false : true

                            }}
                    >
                        {testis.map((item, index) => {
                            return (
                                <SplideSlide>
                                    <ItemsTestimonios item={item} index={index}/>
                                </SplideSlide>
                            )
                        })}


                    </Splide>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Testimonios;


const testis = [
    {
        tipo: "video",
        url: "SZFokTJF4yY",
        nombre: "",
        empresa: "Colombiana de Comercio - Corbeta"
    },
    {
        tipo: "video",
        url: "FFXQj5ZL6MY",
        nombre: "",
        empresa: "Attmosferas"
    },
    {
        tipo: "video",
        url: "PjOivdgUKyw",
        nombre: "",
        empresa: "Operador Logistico D Calidad"
    },




]