/**************************************************
 * Nombre:       Dialogo_Cheking_Ver
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useContext, useEffect, useMemo, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formuario_Checking_Ver from "../Fomularios/Formuario_Checking_Ver";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Pdf_Cheking from "../../Modulo_Pdf/Componentes/Pdf_Cheking";
import {getFechaTimestampSencillo} from "../../Utilidades/getFechaTimestampSencillo";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {Document} from "iconsax-react";
import {usuarioContext} from "../../App";


const Dialogo_Cheking_Ver = ({id, cerrar, setTitulo}) => {
    const {Formulario, setEntidad, entidad, props} = useFormulario({Form: Formuario_Checking_Ver, nombre: 'cheking'})
    const [cargado, setCargando] = useState(false)
    const [datos, setDatos] = useState({})
    const cData = useContext(usuarioContext)


    const guardar = () => {
        setCargando(true)

        let obj = entidad
        if (entidad.estado === 'aprobado') {
            obj.fechaAprobacion = new Date().getTime()
        } else {
            obj.fechaAprobacion = ''

        }


        guardarDoc('checkings', obj).then((dox) => {
            if (dox.res) {
                alert('Cambios realizados con exito')
                cerrar()
            } else {
                alert('Paso algo indesperado, por favor intentelo nuevamente')
            }
            setCargando(false)
        })
    }


    useEffect(() => {

        if (id && id.id) {
            setDatos(id)
            obtenerDoc('checkings', id.id).then((dox) => {
                if (dox.res && dox.data) {
                    setTitulo(dox.data.placa.toUpperCase())
                    setEntidad(dox.data)

                }
            })
        }

    }, [id])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                {datos && datos.placa &&
                <PDFDownloadLink document={<Pdf_Cheking item={datos}/>}
                                 fileName={"Chequeo " + datos.placa + ' ' + getFechaTimestampSencillo(datos.fecha)}
                                 style={{textDecoration: "none"}}> <Button
                    color={'secondary'} startIcon={<Document variant={'Bold'} size={'1rem'}/>}>Descargar</Button>
                </PDFDownloadLink>
                }
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <LoadingButton
                    variant={'contained'}
                    loading={cargado}
                    onClick={() => guardar()}
                >
                    Guardar Cambios
                </LoadingButton>
            </Grid>

        </Grid>
    )
}

export default Dialogo_Cheking_Ver;