/**************************************************
 * Nombre:       Nosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {LINKPORTAFOLIO, TEXTOACENTO} from "../../Constantes";
import ItemNosotros from "./Componentes/ItemNosotros";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import DetalleNosotros from "../Dialogos/DetalleNosotros";
import {theme} from "../../Tema";
import YouTube from "react-youtube";
import {irUrl} from "../../Utilidades/irUrl";


const Nosotros = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Dialogo, abrir} = useDialogo({Componente: DetalleNosotros})


    const opts = {
        height: sCell ? '550' : '550',
        width: sCell ? '308' : '308',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{maxWidth: "1400px", px: sCell ? 3 : 6}}
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <Typography sx={{
                        color: TEXTOACENTO,
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: 600
                    }}>Nosotros</Typography>
                </Grid>


                <Grid item lg={7} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >


                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={250}
                            data-aos-duration="1500"
                            item lg={6} sm={6} xs={12}>
                            <ItemNosotros item={nos} abrir={abrir}/>
                        </Grid>


                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={300}
                            data-aos-duration="1500"
                            item
                            lg={6} sm={6} xs={12}>
                            <ItemNosotros item={mision} abrir={abrir}/>
                        </Grid>


                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={350}
                            data-aos-duration="1500"
                            item lg={6} sm={6} xs={12}>
                            <ItemNosotros item={vision} abrir={abrir}/>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Grid
                        component={"div"}
                        data-aos="fade-up"
                        data-aos-delay={300}
                        data-aos-duration="1500"
                        item
                        container
                        justifyContent={'center'}
                        lg={6} sm={6} xs={12}>
                        <YouTube
                            videoId={'8KHMG4ZIW9U'} // defaults -> null
                            id={'8KHMG4ZIW9U'} // defaults -> null
                            title={"titulo"}
                            opts={opts}
                        />
                    </Grid>
                </Grid>


                <Grid
                    component={"div"}
                    data-aos="fade-up"
                    data-aos-delay={150}
                    data-aos-duration="1500"
                    item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <Button
                        onClick={() => irUrl(LINKPORTAFOLIO)}

                    >Descargar Portafolio</Button>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Nosotros;

const nos = {
    nombre: "NOSOTROS",
    descripcion: "El 3 de agosto de 2016, se constituye INTERNATIONAL LOGISTICS MD SAS, en la cámara de comercio de Ipiales Nariño por sus socios fundadores, como operador logístico de carga, con el propósito firme de convertirse en 5  años en empresa de transporte de carga habilitada por el ministerio de transporte, es así que como año tras año con dedicación y esfuerzo, el 1 de octubre del 2021 se otorgan a la empresa INTERNATIONAL LOGISTICS MD SAS, la habilitación para operar como empresa de servicio público de transporte de carga terrestre BAJO RESOLUCION No. 20213520045595  del MINISTERIO DE TRANSPORTE. \n" +
        "INTERNATIONAL LOGISTCS MD SAS, es una empresa innovadora y diferente que nace con el objetivo de prestar el servicio de transporte de carga por carretera y servicios complementarios al transporte.",

}

const mision = {
    nombre: "MISION",
    descripcion: "Nuestra misión es ofrecer soluciones completas de transporte de carga nacional e internacional, con el objetivo de garantizar la satisfacción de nuestros clientes a través de un servicio confiable, personalizado y de calidad, poniendo especial atención en la seguridad y el cuidado de su mercancía."
}

const vision = {
    nombre: "VISION",
    descripcion: " Nos esforzamos por convertirnos en la empresa líder en transporte de carga nacional e internacional, reconocida por nuestro excepcional servicio al cliente, nuestro compromiso con la seguridad y la calidad, y nuestra contribución al crecimiento y desarrollo del comercio en Sur y Norte América"
}