import {Facebook, Instagram, Map, Whatsapp} from "iconsax-react";

export const ACENTO = '#FF6623'

export const DIRECCIONURL = 'https://internationallogisticsmd.com/'

export const LINKWASS = "https://api.whatsapp.com/send?phone=573164738993&text=Hola%20necesito%20mas%20informacion"

export const LINKCAL = "https://calendly.com/internationallogisticsmdsgcs/presentacion"

export const LINKMAP = "https://www.google.com/maps/dir/?api=1&destination=0.829799,-77.635449,190&dir_action=navigate"

export const LINKMAIL = 'mailto:?subject=Asunto del mensaje&body=Contenido'

export const URLDEFAULT = "https://internationallogisticsmd.com"

export const ESTADOS = ['nuevo', 'contactando', 'seguimiento', 'archivado']

export const URLPAGINA = 'https://internationallogisticsmd.com/'

export const LINKPORTAFOLIO = 'https://internationallogisticsmd.com/recursosweb/BROCHURE_MD.pdf'

export const REDES = [
    {
        nombre: 'facebook',
        url: "https://www.facebook.com/profile.php?id=100063727893055&mibextid=LQQJ4d",
        Icono: Facebook
    },
    {
        nombre: 'instagram',
        url: "https://instagram.com/mdinternational2021?igshid=OGQ5ZDc2ODk2ZA==",
        Icono: Instagram
    },
    {
        nombre: 'whatsapp',
        url: LINKWASS,
        Icono: Whatsapp
    },
    {
        nombre: 'google_maps',
        url: LINKMAP,
        Icono: Map
    },
]


export const TEXTOACENTO = "#FF6623"
export const TEXTOSUAVE = "#4E4E4E"

export const ESTADOSCHEKING = ['creado', 'diligenciado', 'aprobado']

export const TIPOS = ['CAMION', 'DOBLE TROQUE', 'TRACTO CAMION', 'KARRY', 'PATINETA']

export const CARROSERIAS = ['SRS', 'PLATAFORMA', 'FURGON', 'ESTACAS', 'CISTERNA O TANQUE', 'VAN', 'CONTENEDOR DE CARGA']



