/**************************************************
 * Nombre:       Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {ACENTO} from "../../Constantes";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Pqrs from "../Formularios/Formulario_Pqrs";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerToken} from "../../Modulo_Notificaciones/Funciones/obtenerToken";
import {verificarPermiso} from "../../Modulo_Notificaciones/Funciones/verificarPermiso";
import {enviarNotificacionTopic} from "../../Modulo_Notificaciones/Funciones/enviarNotificacionTopic";
import {theme} from "../../Tema";


const Pqrs = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({Form: Formulario_Pqrs, nombre: 'pqrs'})
    const [cargando, setCargando] = useState(false)


    const guardar = () => {

        let obj = entidad
        obj.fecha = new Date().getTime()
        setCargando(true)

        verificarPermiso().then((dox) => {
            if (!dox.res) {
                alert('Porfavor activa las notificaciones para que puedas saber el procesos de tu PQRS')
            }

        })


        obtenerToken().then((dox) => {
            if (dox.res) {
                obj.token = dox.data
            }
            obj.archivada = false
            guardarDoc('pqrs', obj).then((doc) => {
                if (doc.res) {
                    enviarNotificacionTopic('torre', 'PQRS nueva', obj.nombre + ' apuesto una PQRS')
                    alert('PQRS enviada con exito, pronto recibiras la respuesta por favor este atento a correo o WhatsApp registrado')
                    limpiarEntidad()
                } else {
                    alert("Algo indesperado ocurrio por favor intentalo mas tarde")
                }
                setCargando(false)
            })


        })


    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{marginTop: 8}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{color: ACENTO, fontWeight: 600, fontSize: 24}}>PQRS</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{
                        fontSize: sCell ? 14 : 18,
                        width: sCell ? '80%' : '50%',
                        textAlign: 'center',
                        fontFamily: 'Roboto'
                    }}>Por que la
                        calidad
                        de nuestro
                        servicio es importante para todos nuestros procesos queremos saber tu peticiones, solicitudes
                        reclamos y sugerencias.</Typography>
                </Grid>

                <Grid item container lg={5} sm={8} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Formulario {...props} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <LoadingButton
                        loading={cargando}
                        onClick={() => guardar()}
                        variant={'contained'}>
                        Enviar
                    </LoadingButton>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Pqrs;