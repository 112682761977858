/**************************************************
 * Nombre:       Inicio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import contenedor from '../../Recursos/contenedor.webp'
import contenedorsolo from '../../Recursos/contenedorsolo.webp'
import {ArrowCircleRight, ArrowCircleRight2, ArrowRight, ArrowRight2} from "iconsax-react";
import BotonesAccion from "./Componentes/BotonesAccion";
import {theme} from "../../Tema";


const Inicio = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: masSM ? 6 : 0}}
            >

                <Grid
                    component={"div"}
                    data-aos="fade-down"
                    data-aos-delay={500}
                    data-aos-duration="1500"
                    item container lg={7} sm={12} xs={12} sx={{
                    transition: "all 1.5s ease-in-out",
                    zIndex: 2,
                    justifyContent: "center", marginTop: sCell ? 4 : 0
                }}>
                    <img src={sCell ? contenedorsolo : contenedor} width={sTab ? "0%" : "100%"} height={"auto"}
                         alt={"Contenedor de carga"} title={"Contenedor de carga"}/>
                </Grid>

                <Grid item lg={5} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{px: 4}}
                        spacing={sTab ? 4 : 0}
                    >

                        <Grid item lg={12} sm={6} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={450}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 2 : 10}}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: 30,
                                            fontWeight: 800,
                                            lineHeight: 1.1,
                                            textAlign: sCell ? "center" : "left"
                                        }}>Transporte
                                        de
                                        mercancia Nacional e Internacional</Typography>
                                </Grid>

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={500}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Typography
                                        component={'p'}
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                            lineHeight: 1.4,
                                            textAlign: sCell ? "center" : "left"
                                        }}>
                                        Operaciones de transporte de carga masiva, nacional e internacional con aliados
                                        estratégicos, para prestar un servicio eficiente, seguro y de calidad.
                                    </Typography>
                                </Grid>

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={550}
                                    data-aos-duration="1500"
                                    container item lg={12} sm={12} xs={12}
                                    sx={{marginTop: 1, justifyContent: sCell ? "center" : "flex-start"}}>
                                    <Button
                                        href={"#Mas"}
                                        variant={"Text"}
                                        endIcon={<ArrowRight2 size={"1rem"}/>}
                                        sx={{px: 0, color: "#FF6623"}}
                                    >Ver todos los servicios </Button>
                                </Grid>

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={550}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: 3}}>
                                    <BotonesAccion/>
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item lg={12} sm={6} xs={12} sx={{marginTop: sTab ? 6 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={600}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 8 : 4}}>
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontSize: 30,
                                            fontWeight: 800,
                                            lineHeight: 1.1,
                                            textAlign: sCell ? "center" : "left"
                                        }}>
                                        Seguro de Mercancias
                                    </Typography>
                                </Grid>

                                <Grid

                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={650}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 4 : 2}}>
                                    <Typography sx={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        lineHeight: 1.4,
                                        textAlign: sCell ? "center" : "left"
                                    }}>
                                        Servicio de seguro de mercancía y logística, seguimiento, acompañamiento y
                                        reporte las
                                        24 horas del día, en todos los movimientos que se realizan, contamos con
                                        monitoreo y
                                        personal idóneo para reacciones oportunas.
                                    </Typography>
                                </Grid>


                                <Grid
                                    component={"div"}
                                    data-aos="fade-up"
                                    data-aos-delay={700}
                                    data-aos-duration="1500"
                                    item lg={12} sm={12} xs={12} sx={{marginTop: sTab ? 6 : 3}}>
                                    <BotonesAccion/>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default Inicio;