/**************************************************
 * Nombre:       Formulario_Parte
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase, ImagenDirecta} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {GalleryAdd} from "iconsax-react";


const Formulario_Parte = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"

            >


                <Grid item lg={1} sm={1} xs={1}>

                </Grid>

                <Grid item lg={10} sm={10} xs={10}>
                    <FormBase.ImagenDirecta Icono={GalleryAdd} dato={'img'} funcion={subirUnaImagenCroper}
                                            carpeta={'parte'} y={2}
                                            x={3}/>
                </Grid>

                <Grid item lg={1} sm={1} xs={1}>

                </Grid>
                {props.entidad && props.entidad.img &&

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        {props && props.preguntas.map((it, index) => {
                            return (
                                <Grid key={index} item lg={12} sm={12} xs={12}>
                                    <FormBase.InputOpcionesGrup label={it} dato={it}
                                                                opciones={['bueno', 'malo', 'no aplica']}/>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>
                }


            </Grid>
        </FormBase>
    )
}

export default Formulario_Parte;