/**************************************************
 * Nombre:       Tarjeta_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Book, Buildings, Flag, Lock, Lovely, Message, Message2, Mobile, Moneys, Sms} from "iconsax-react";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";


const Tarjeta_Potenciales = ({valores, click, difiere}) => {

    return (

        <CardBase valores={valores} sombra={0} >
            <ButtonBase onClick={() => click(valores)}
                        sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 4}}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{backgroundColor: '#EBEBEB', borderRadius: 2, p: 2}}
                    spacing={1}
                >

                    <Grid item lg={3} sm={6} xs={12}>
                        <CardBase.FechaComponente dato1={'fecha'}>
                            <CardBase.IconTexto Icono={Book} dato1={"nombre"} peso={800}/>
                        </CardBase.FechaComponente>
                    </Grid>

                    <Grid item lg={2} sm={6} xs={6}>
                        <CardBase.IconTexto Icono={Flag} dato1={"estado"}/>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={6}>
                        <CardBase.IconTexto Icono={Mobile} dato1={"wass"}/>
                    </Grid>

                    <Grid item lg={3} sm={6} xs={6}>
                        <CardBase.IconTexto Icono={Sms} dato1={"correo"}/>
                    </Grid>


                </Grid>
            </ButtonBase>
        </CardBase>


    )
}

export default Tarjeta_Potenciales;