/**************************************************
 * Nombre:       Formulario_Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import {FormBase, ImagenBoton, InputCheckGroup} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Messages, Sms, User, Whatsapp} from "iconsax-react";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {theme} from "../../Tema";


const Formulario_Pqrs = (props) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <FormBase {...props} >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Nombre (opcional)'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={Whatsapp} label={'WhatsApps (opcional)'} dato={'wass'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Sms} label={'Correo (opcional)'} dato={'correo'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Messages} label={'Mensaje'} dato={'mensaje'} lineas={4}/>
                </Grid>


                <Grid item lg={8} sm={12} xs={12}>
                    <FormBase.InputCheckGroup dato={'tipo'}
                                              opciones={['Peticion', 'Queja', 'Reclamo', 'Sugerencia', 'Denuncia']}
                                              resaltar={['Denuncia']}
                                              lg={4} xs={4} sm={3}/>
                </Grid>

                <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: "center", mt: sCell ? 2 : 0}}>
                    <FormBase.ImagenBoton color={'secondary'} label={'Subir Evidencia (opcional)'} dato={'img'}
                                          funcion={subirUnaImagenCroper}/>

                </Grid>


            </Grid>

        </FormBase>
    )
}

export default Formulario_Pqrs;