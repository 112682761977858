/**************************************************
 * Nombre:       ItemNosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {TEXTOACENTO} from "../../../Constantes";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleServicio from "../../Dialogos/DetalleServicio";
import DetalleNosotros from "../../Dialogos/DetalleNosotros";
import {theme} from "../../../Tema";


const ItemNosotros = ({item, abrir}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <ButtonBase
            onClick={() => abrir(item)}
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{p: masSM ? 4 : 2}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{
                        fontSize: 18,
                        fontFamily: "Montserrat",
                        fontWeight: 800
                    }}>{item.nombre}</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                    <Typography sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "center",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 6,
                        textOverflow: "ellipsis",
                    }}>{item.descripcion}</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                    <Button variant={"text"} sx={{color: TEXTOACENTO}}>Ver Mas</Button>
                </Grid>


            </Grid>
        </ButtonBase>
    )
}

export default ItemNosotros;