import {useEntidadLocal} from "./useEntidadLocal";

export const useFormulario = ({Form, nombre, datosDefault = {}, almacenarLocal = false, preguntas = []}) => {
    const {entidad, cambioEntidad, limpiarEntidad, setEntidad} = useEntidadLocal({
        nombre: nombre,
        datosDefault,
        almacenarLocal: almacenarLocal
    })

    const setDato = (key, valor) => {
        setEntidad({...entidad, [key]: valor})
    }


    const props = {
        entidad: entidad,
        cambio: cambioEntidad,
        setDato: setDato,
        preguntas: preguntas,
        setEntidad: setEntidad
    }

    return {
        props,
        Formulario: Form,
        limpiarEntidad,
        entidad,
        setEntidad

    }
}