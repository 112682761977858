/**************************************************
 * Nombre:       Formulario_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import {FormBase, InputArchivo} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {
    Bank,
    Book,
    Buildings,
    ClipboardText,
    Flag,
    Lovely,
    Message,
    Message2,
    Mobile,
    Money,
    Sms,
    Whatsapp
} from "iconsax-react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {ESTADOS} from "../../Constantes";


const Formulario_Potencial = (props) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIconSelect Icono={Flag} label={'Estado'} dato={'estado'} opciones={ESTADOS}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>

                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Book} label={'Nombre'} dato={'nombre'} editable={false}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Sms} label={'Correo'} dato={'correo'} editable={false}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Mobile} label={'Celular'} dato={'wass'} editable={false}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={ClipboardText} label={'Mensaje del usuario'} dato={'mensaje'}
                                        editable={false} lineas={6}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={ClipboardText} label={'Notas'} dato={'notas'} lineas={6}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Potencial;