import {fire} from "../../fire";
import {
    setDoc,
    doc,
    deleteDoc,
    onSnapshot,
    updateDoc,
    writeBatch,
    getDocs,
    collection,
    arrayUnion
} from 'firebase/firestore'

export const adicionarElemtoArray = (col, id, array, elemento) => {


    return new Promise(resolve => {
        updateDoc(doc(fire, col, id), {[array]: arrayUnion(elemento)}).then((dox) => {
            return resolve({res: true, data: dox})
        }).catch((err) => {
            //  alert(err.message)
            return resolve({res: false, data: err.message})
        })
    })

}