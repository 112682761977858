import {getMessaging, onMessage} from "firebase/messaging";
import {app} from "../../fire";
import {IconButton, Snackbar} from "@mui/material";
import {useState} from 'react';
import {CloseSquare} from "iconsax-react";

export const useEscucharNotificaion = () => {
    const [open, setOpen] = useState(false)
    const messaging = getMessaging(app);
    const [data, setData] = useState('')
    
    try {
        onMessage(messaging, (payload) => {
            // console.log('Message received. ', payload);
            setData(payload.notification)
            setOpen(true)

        });   
    }catch (e) {

    }
   

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpen(false)}
            >
                <CloseSquare fontSize="small"/>
            </IconButton>
        </>
    );


    const Notificacion = () => {
        return (
            <Snackbar
                open={open}
                message={data.body}
                action={action}
                anchorOrigin={{horizontal: 'center', vertical: 'center'}}
            />
        )
    }


    return ({
            Notificacion,
            setOpen,
        }
    )

}