/**************************************************
 * Nombre:       NoticiaActual
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Grid, Typography} from "@mui/material";
import {ACENTO} from "../../../Constantes";
import Compartir from "./Compartir";
import CuerpoNoticia from "./CuerpoNoticia";
import {irUrl} from "../../../Utilidades/irUrl";
import {Helmet} from "react-helmet";


const NoticiaActual = ({item = {}}) => {
    const [mostrar, setMostrar] = useState(false)

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{maxWidth: '1400px', px: 3}}
        >

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Boletines vias de Colombia | Trasnporte de Carga MD</title>
                <meta property="og:title" content={item.nombre}/>
                <meta property="og:image" content={item.img}/>
                <meta property="og:description"
                      content={item.resumen}/>
            </Helmet>

            <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Grid
                    container
                    direction="row-reverse"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={6}
                >

                    <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: "center",}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <img src={item.img} onLoad={() => setMostrar(true)} width={'100%'} height={'auto'}
                                     alt={item.titulo}
                                     style={{borderRadius: 20}}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -30}}>
                                {mostrar &&
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    sx={{
                                        height: 250,
                                        background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
                                        px: 3,
                                        py: 3,
                                        borderBottomLeftRadius: 20,
                                        borderBottomRightRadius: 20
                                    }}
                                />}
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item lg={7} sm={7} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <CuerpoNoticia item={item}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                <Compartir item={item}/>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                        <Typography sx={{fontSize: 16, fontWeight: 400}}>{item.noticia}
                            <span
                                onClick={() => irUrl(item.fuente)}
                                style={{fontWeight: 700, color: ACENTO, marginLeft: 6, cursor: 'pointer'}}>Link de noticia fuente</span>
                        </Typography>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )
}

export default NoticiaActual;


