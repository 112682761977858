/**************************************************
 * Nombre:       Contactanos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '.././../../Recursos/logo.svg'
import {ACENTO} from "../../../Constantes";
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Contactanos from "../../Formularios/Formulario_Contactanos";
import {validadorDatos} from "../../../Modulo_Formularios/Funciones/validadorDatos";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import {LoadingButton} from "@mui/lab";
import {theme} from "../../../Tema";


const Contactanos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Contactanos,
        nombre: 'contactanos'
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        if (!validadorDatos({entidad: entidad, datos: ['nombre', 'correo']})) {
            return;
        }

        setCargando(true)
        let obj = entidad
        obj.fecha = new Date().getTime()
        obj.estado = 'nuevo'

        guardarDoc('potenciales', obj).then((dox) => {
            if (dox.res) {
                alert('Datos enviados con exito')
                limpiarEntidad()
            } else {
                alert('Algo inesperado paso, por favor intentalo nuevamente')
            }
            setCargando(false)
        })

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: masSM ? 0 : 3}}
        >

            <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={masSM ? 10 : 6}
                >

                    <Grid item lg={4} sm={4} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: masSM ? 'center' : "flex-start"}}>
                                <img src={logo} width={250} height={'auto'} alt={'logo'} title={'logo'}/>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{
                                marginTop: 1,
                                justifyContent: sCell ? 'center' : "flex-start",
                                px: sCell ? 3 : 0
                            }}>

                                <Typography
                                    sx={{fontSize: 16, fontWeight: 400, textAlign: sCell ? 'center' : 'left'}}>
                                    Somos una sociedad privada dedicada a ofrecer soluciones integrales de logística y
                                    seguros de mercancía.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item lg={1} sm={1} xs={12}>

                    </Grid>

                    <Grid item lg={7} sm={6} xs={12} sx={{marginTop: sCell ? -10 : 0}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"

                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <Typography sx={{fontSize: 16, fontWeight: 500, color: ACENTO}}>
                                    Contactanos
                                </Typography>
                            </Grid>

                            <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 2}}>
                                <Formulario {...props} />
                            </Grid>

                            <Grid item container lg={11} sm={12} xs={12}
                                  sx={{justifyContent: "flex-end", marginTop: 2}}>
                                <LoadingButton
                                    onClick={() => guardar()}
                                    loading={cargando}
                                    sx={{px: 8}}
                                    variant={'contained'}>Enviar</LoadingButton>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>
            </Grid>

        </Grid>
    )
}

export default Contactanos;