/**************************************************
 * Nombre:       Dialogo_Boletines
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Boletin from "../Fomularios/Formulario_Boletin";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {validadorDatos} from "../../Modulo_Formularios/Funciones/validadorDatos";
import {enviarNotificacionMultipleToken} from "../../Modulo_Notificaciones/Funciones/enviarNotificacionMultipleToken";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {URLPAGINA} from "../../Constantes";
import {copiarTexto} from "../../Utilidades/copiarTexto";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";


const Dialogo_Boletines = ({cerrar, id}) => {
    const {Formulario, props, entidad, limpiarEntidad, setEntidad} = useFormulario({
        Form: Formulario_Boletin,
        nombre: 'boletin'
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {

        if (!validadorDatos({entidad: entidad, datos: ['titulo', 'resumen', 'fuente', 'fecha', 'img']})) {
            return
        }

        setCargando(true)

        guardarDoc('boletines', entidad).then((dox) => {
            if (dox.res) {
                alert('Cambios guardados con exito')
                if (!entidad.id) {
                    enviarNotis()
                }
                limpiarEntidad()
                cerrar()
            } else {
                alert('Algo inesperado paso, por favor intentalo nuevamente')
            }
            setCargando(false)
        })
    }


    const borrar = () => {

        borrarDoc('boletines', entidad.id).then((dox) => {
            if (dox.res) {
                alert('Elemento borrado')
                limpiarEntidad()
                cerrar()
            }
        })

    }

    const enviarNotis = () => {
        obtenerDoc('tokens', 'tokens').then((dox) => {
            if (dox.res) {
                let lista = dox.data.lista
                enviarNotificacionMultipleToken(lista, entidad.titulo, entidad.resumen, entidad.img)
            }
        })

    }


    useEffect(() => {
        if (id) {
            setEntidad(id)
        }
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                {props && props.entidad.id &&
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >

                    <Grid item lg={8} sm={8} xs={8}>
                        <Typography id={'copi'} sx={{
                            fontWeight: 500, fontSize: 12, display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                        }}>
                            {URLPAGINA + props.entidad.id}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "flex-end"}}>
                        <ButtonBase
                            onClick={() => copiarTexto('copi')}
                            sx={{
                                backgroundColor: '#00000090',
                                px: 1,
                                py: 0.2,
                                borderRadius: 2
                            }}><Typography sx={{fontSize: 12, color: '#fff'}}>Copiar Url</Typography></ButtonBase>
                    </Grid>

                    <Grid item lg={8} sm={8} xs={8}>
                        <Typography id={'color'} sx={{
                            fontWeight: 500, fontSize: 12, display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                        }}>
                            {'#FF6623'}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "flex-end"}}>
                        <ButtonBase
                            onClick={() => copiarTexto('color')}
                            sx={{
                                backgroundColor: '#00000090',
                                px: 1,
                                py: 0.2,
                                borderRadius: 2
                            }}><Typography sx={{fontSize: 12, color: '#fff'}}>Copiar Color</Typography></ButtonBase>
                    </Grid>

                    <Grid item lg={8} sm={8} xs={8}>
                        <Typography id={'titulo'} sx={{
                            fontWeight: 500, fontSize: 12, display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                        }}>
                            {props.entidad.titulo}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "flex-end"}}>
                        <ButtonBase
                            onClick={() => copiarTexto('titulo')}
                            sx={{
                                backgroundColor: '#00000090',
                                px: 1,
                                py: 0.2,
                                borderRadius: 2
                            }}><Typography sx={{fontSize: 12, color: '#fff'}}>Copiar titulo</Typography></ButtonBase>
                    </Grid>

                    <Grid item lg={8} sm={8} xs={8}>
                        <Typography id={'res'} sx={{
                            fontWeight: 500, fontSize: 12, display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                        }}>
                            {props.entidad.resumen}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "flex-end"}}>
                        <ButtonBase
                            onClick={() => copiarTexto('res')}
                            sx={{
                                backgroundColor: '#00000090',
                                px: 1,
                                py: 0.2,
                                borderRadius: 2
                            }}><Typography sx={{fontSize: 12, color: '#fff'}}>Copiar resumen</Typography></ButtonBase>
                    </Grid>


                    <Grid item lg={8} sm={8} xs={8}>
                        <Typography id={'res'} sx={{
                            fontWeight: 500, fontSize: 12, display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                        }}>
                            {'boletines@internationallogisticsmd.com'}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "flex-end"}}>
                        <ButtonBase
                            onClick={() => copiarTexto('res')}
                            sx={{
                                backgroundColor: '#00000090',
                                px: 1,
                                py: 0.2,
                                borderRadius: 2
                            }}><Typography sx={{fontSize: 12, color: '#fff'}}>Copiar correo</Typography></ButtonBase>
                    </Grid>

                </Grid>
                }
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4, mb: 1}}>
                <LoadingButton
                    onClick={() => guardar()}
                    loading={cargando}
                    variant={'contained'}>
                    Guardar Cambios
                </LoadingButton>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                {entidad.id && entidad.id !== '1689180530586OKS' &&
                <Button
                    onClick={() => borrar()}
                    color={'success'} size={'small'} variant={'text'} sx={{color: "#00000050"}}>Borrar</Button>
                }
            </Grid>


        </Grid>
    )
}

export default Dialogo_Boletines;