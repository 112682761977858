/**************************************************
 * Nombre:       Equipo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import per_1 from '../../Recursos/per_1.webp'
import per_2 from '../../Recursos/per_2.webp'
import per_3 from '../../Recursos/per_3.webp'
import per_4 from '../../Recursos/per_4.webp'
import per_5 from '../../Recursos/per_5.webp'
import {TEXTOACENTO} from "../../Constantes";
import BotonesAccion from "./Componentes/BotonesAccion";
import {theme} from "../../Tema";

const Equipo = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"

        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: sCell ? 3 : 8}}
            >


                <Grid item lg={7} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        {imgs.map((item, index) => {
                            return (
                                <Grid
                                    key={index}
                                    component={"div"}
                                    data-aos="fade-down"
                                    data-aos-delay={250 * index}
                                    data-aos-duration="1500"
                                    item lg={2} sm={2.33} xs={2.33}>
                                    <img src={item} width={"100%"} height={"auto"} alt={"Personas Equipo de Trabajo"}
                                         title={"Personas"} loading={'lazy'}/>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>

                <Grid item lg={5} sm={12} xs={12} sx={{marginTop: masSM ? 0 : 6}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid
                            item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                            <Typography sx={{
                                color: TEXTOACENTO,
                                fontSize: 14,
                                fontFamily: "Montserrat",
                                fontWeight: 600
                            }}>Equipo de trabajo</Typography>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={300}
                            data-aos-duration="1500"
                            item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <Typography
                                sx={{
                                    fontSize: 32,
                                    fontFamily: "Montserrat",
                                    fontWeight: 800,
                                    textAlign: sCell ? "center" : "right",
                                    lineHeight: 1.1
                                }}>
                                Somos profesionales en lo que hacemos
                            </Typography>
                        </Grid>


                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={350}
                            data-aos-duration="1500"
                            item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 3 : 1}}>
                            <Typography sx={{fontSize: 18, fontWeight: 400, textAlign: sCell ? "center" : "right"}}>
                                contamos con un equipo profesional y calificado para analizar y enfrentar todas las
                                tareas y retos, anticipándonos a situaciones que podrían escalar a una mala experiencia
                                al cliente, resolviendo siempre con eficiencia y eficacia.
                            </Typography>
                        </Grid>

                        <Grid
                            component={"div"}
                            data-aos="fade-up"
                            data-aos-delay={400}
                            data-aos-duration="1500"
                            item container lg={5} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 6}}>
                            <BotonesAccion vertical={true} dir={"flex-end"}/>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default Equipo;

const imgs = [
    per_1,
    per_2,
    per_3,
    per_4,
    //per_5,
]