/**************************************************
 * Nombre:       NoticiaImagenTitulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import imagen from '../../../Recursos/rectangulo.png'
import {useNavigate} from "react-router-dom";
import {theme} from "../../../Tema";


const NoticiaImagenTitulo = ({difiere = false, item = {}}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()
    const [mostrar,setMostrar] = useState(false)


    return (
        <ButtonBase
            onClick={() => navigate('/Boletines/' + item.id)}
            sx={{borderRadius: 4, textAlign: 'left', '&:hover': {boxShadow: 14}}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >


                <Grid item lg={12} sm={12} xs={12}>
                    {item && item.img &&
                    <img src={item.img} width={'100%'} onLoad={() => setMostrar(true)}  height={'auto'} alt={'imagen'} title={'imagen'}
                         style={{borderRadius: 20}}/>
                    }
                </Grid>

                <Grid item lg={12} sm={12} xs={12}
                      sx={{marginTop: difiere ? (sCell ? -12.2 : -22.3) : -30}}>
                    {item && item.img && mostrar &&
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        sx={{
                            height: difiere ? (sCell ? 100 : 180 ) : 250,
                            background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
                            px: sCell ? 2 : 3,
                            py: sCell ? 2 : 3,
                            borderBottomLeftRadius: 18,
                            borderBottomRightRadius: 18
                        }}
                    >

                        <Grid item lg={12} sm={12} xs={12}>

                            <Typography sx={{
                                fontSize: difiere ? (sCell ? 12 : 14 ) : (sCell ? 14 : 20),
                                fontFamily: "Montserrat",
                                fontWeight: 800,
                                color: '#fff',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",
                            }}>
                                {item.titulo}
                            </Typography>
                        </Grid>

                    </Grid>
                    }

                </Grid>

            </Grid>
        </ButtonBase>
    )
}

export default NoticiaImagenTitulo;