/**************************************************
 * Nombre:       Tarjeta_Boletines
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Text} from "iconsax-react";


const Tarjeta_Boletines = ({valores, click}) => {

    return (
        <CardBase valores={valores} sombra={0}>
            <ButtonBase onClick={() => click(valores)}
                        sx={{width: '100%', textAlign: 'left', borderRadius: 2, '&:hover': {boxShadow: 4}}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{backgroundColor: '#EBEBEB', borderRadius: 2, p: 2}}
                >
                    <Grid item lg={12} sm={12} xs={12}>
                        <CardBase.IconTexto dato1={'titulo'} Icono={Text}/>
                    </Grid>


                </Grid>
            </ButtonBase>
        </CardBase>
    )
}

export default Tarjeta_Boletines;