/**************************************************
 * Nombre:       NoticiasRecientes
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import NoticiaImagenTitulo from "./NoticiaImagenTitulo";


const NoticiasRecientes = ({data = []}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{px: 6}}
                    spacing={4}
                >

                    <Grid item lg={5} sm={6} xs={12}>
                        <NoticiaImagenTitulo item={data[0]}/>
                    </Grid>

                    <Grid item lg={7} sm={12} xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={4}
                        >

                            <Grid item lg={4} sm={6} xs={6}>
                                <NoticiaImagenTitulo difiere={true} item={data[1]}/>
                            </Grid>

                            <Grid item lg={4} sm={6} xs={6}>
                                <NoticiaImagenTitulo difiere={true} item={data[2]}/>
                            </Grid>

                            <Grid item lg={4} sm={6} xs={6}>
                                <NoticiaImagenTitulo difiere={true} item={data[3]}/>
                            </Grid>

                            <Grid item lg={4} sm={6} xs={6}>
                                <NoticiaImagenTitulo difiere={true} item={data[4]}/>
                            </Grid>

                            <Grid item lg={4} sm={6} xs={6}>
                                <NoticiaImagenTitulo difiere={true} item={data[5]}/>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )
}

export default NoticiasRecientes;