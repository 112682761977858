/**************************************************
 * Nombre:       NoticiaImagenTitulo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import imagen from '../../../Recursos/rectangulo.png'
import {useNavigate} from "react-router-dom";
import {theme} from "../../../Tema";

const NoticiaImagenTituloPeq = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const navigate = useNavigate()

    return (
        <ButtonBase
            onClick={() => navigate('/Boletines/' + item.id)}
            sx={{textAlign: 'left', borderRadius: 8}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >


                <Grid item lg={12} sm={12} xs={12}
                      sx={{maxHeight: sCell ? 160 : 200, overflow: 'hidden', borderRadius: sCell ? 6 : 8}}>
                    <img src={item.img} width={'100%'} height={'auto'} alt={'imagen'} title={'imagen'}
                    />
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2, px: 1,}}>
                    <Typography sx={{fontSize: 16, fontFamily: "Montserrat", fontWeight: 800}}>
                        {item.titulo}
                    </Typography>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1, px: 1,}}>
                    <Typography sx={{
                        fontSize: 16, fontWeight: 500, display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        textOverflow: "ellipsis",
                    }}>
                        {item.resumen}
                    </Typography>
                </Grid>

            </Grid>
        </ButtonBase>
    )
}

export default NoticiaImagenTituloPeq;