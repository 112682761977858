import axios from 'axios'
import {APIKEYSERVIDOR} from "../Constantes";

export const enviarNotificacionTopic = (topic, titulo = "", texto = "", img = null,) => {
    const fcm = "https://fcm.googleapis.com/fcm/send"

    // corregir si imagen

    let message = {

        "to": "/topics/" + topic,
        "notification": {
            "body": texto,
            "title": titulo,

        },
        android: {
            notification: {
                sound: 'default'
            },
        },
        apns: {
            payload: {
                aps: {
                    sound: 'default'
                },
            },
        },
        webpush: {
            notification: {
                sound: 'default'
            },
        },

    }

    if (img !== null) {
        message.notification.image = img;
    }


    let headers = {
        "Authorization": "key=" + APIKEYSERVIDOR,
        "Content-Type": "application/json",
    }

    axios.post(fcm, message, {headers}).then((doc) => {
    }).catch((err) => {
        alert(err)
    })
}