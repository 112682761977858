/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import {iniciarAnaliticas} from "../Servicios/Analiticas/iniciarAnaliticas";
import Boletines from "./Secciones/Boletines";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Main from "./Secciones/Main";
import Checking from "./Secciones/Checking";


const Pagina = () => {


    useEffect(() => {
        AOS.init({
            offset: 0
        });
        AOS.refresh();


        iniciarAnaliticas()

    }, []);


    return (
        <Router>

            <Routes>

                <Route path={"/Boletines/:id"} element={<Boletines/>}/>
                <Route path={"/Home"} element={<Main/>}/>
                <Route path={"/Checking/:id"} element={<Checking/>}/>
                <Route path={"/*"} element={<Main/>}/>


            </Routes>

        </Router>

    )
}

export default Pagina;

