/**************************************************
 * Nombre:       BarraSuperior
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {REDES} from "../../../Constantes";
import banderas from "../../../Recursos/banderas.svg";
import {irUrl} from "../../../Utilidades/irUrl";


const BarraSuperior = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >

            <Grid item container lg={5} sm={6} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{color: "#fff", fontSize: 14}}>Oficina Ipiales Cra 1a # 12-09 Barrio Victoria
                    Norte </Typography>
            </Grid>

            <Grid item lg={2} sm={2} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {REDES.map((item) => {
                        return (
                            <Grid item container lg={3} sm={3} xs={12}
                                  sx={{justifyContent: "center"}}>
                                <ButtonBase
                                    onClick={() => irUrl(item.url)}
                                >
                                    <item.Icono color={"#fff"} variant={"Bulk"} size={"1.8rem"}/>
                                </ButtonBase>

                            </Grid>
                        )
                    })}


                </Grid>
            </Grid>

            <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: "flex-end"}}>
                <img src={banderas} width={130} height={"auto"}
                     alt={"Banderas de Colombia, Ecuador y Peru"}  title={"Banderas de Colombia, Ecuador y Peru"}/>
            </Grid>


        </Grid>
    )
}

export default BarraSuperior;