export const getFechaTimestampSencillo = (time) => {

    let date = new Date(time)
    const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    let dia = date.getDate();
    let mes = date.getMonth();
    let year = date.getFullYear();

    let hora = date.getHours() + ":" + date.getMinutes();

    let texto = dia + " " + months[mes] + " " + year

    return texto

}