/**************************************************
 * Nombre:       Formulario_Ebook
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, ButtonBase, Grid, Typography} from "@mui/material";
import {FormBase, InputArchivo} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {
    Bank,
    Book,
    Buildings,
    ClipboardText,
    Flag,
    Lovely,
    Message,
    Message2,
    Mobile,
    Money,
    Sms,
    Whatsapp
} from "iconsax-react";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {enviarNotificacionToken} from "../../Modulo_Notificaciones/Funciones/enviarNotificacionToken";
import {irUrl} from "../../Utilidades/irUrl";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const Formulario_Pqrs = (props) => {


    const enviarWass = () => {
        iniciarChatWhatsApp({num: props.entidad.celular})
        if (props.entidad.token) {
            enviarNotificacionToken({
                token: props.entidad.token,
                titulo: 'Respuesta a PQRS',
                texto: 'Su respuesta se ha enviado al numero WhatsApp registrado',
            })
        }

    }

    const enviarSms = () => {
        irUrl("mailto:" + props.entidad.correo + "?subject=Asunto del mensaje&body=Contenido")
        if (props.entidad.token) {
            enviarNotificacionToken({
                token: props.entidad.token,
                titulo: 'Respuesta a PQRS',
                texto: 'Su respuesta se ha enviado al correo registrado',
            })
        }
    }

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item lg={8} sm={8} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Book} label={'Nombre'} dato={'nombre'} editable={false}/>
                </Grid>

                <Grid item lg={4} sm={4} xs={7}>
                    <FormBase.InputIcon Icono={Book} label={'Tipo'} dato={'tipo'} editable={false}/>
                </Grid>

                <Grid item lg={8} sm={8} xs={7} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Sms} label={'Correo'} dato={'correo'} editable={false}/>
                </Grid>

                <Grid item lg={4} sm={4} xs={5}>
                    <Button
                        onClick={() => enviarSms()}
                        startIcon={<Sms/>}>Enviar</Button>
                </Grid>

                <Grid item lg={8} sm={8} xs={7} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={Mobile} label={'Celular'} dato={'wass'} editable={false}/>
                </Grid>

                <Grid item lg={4} sm={4} xs={5}>
                    <Button
                        onClick={() => enviarWass()}
                        startIcon={<Whatsapp/>}>Enviar</Button>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                    <FormBase.InputIcon Icono={ClipboardText} label={'Mensaje del usuario'} dato={'mensaje'}
                                        editable={false} lineas={6}/>
                </Grid>


                <Grid item lg={3} sm={3} xs={3}>

                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "center"}}>
                    <Zoom>
                        <img
                            src={props.entidad.img}
                            width={'100%'}
                        />
                    </Zoom>
                </Grid>

                <Grid item lg={3} sm={3} xs={3}>

                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                    <FormBase.InputIcon Icono={ClipboardText} label={'Notas'} dato={'notas'} lineas={6}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Pqrs;