export const validadorDatos = ({entidad, datos = []}) => {

    for (let i = 0; i < datos.length; i++) {
        if (!entidad[datos[i]]) {
            alert(datos[i] + ' es obligatorio para esta accion')
            return false
        }
    }

    return true

}