/**************************************************
 * Nombre:       DetalleServicio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography} from "@mui/material";
import {TEXTOACENTO, TEXTOSUAVE} from "../../Constantes";
import BotonesAccion from "../SubSecciones/Componentes/BotonesAccion";


const DetalleServicio = ({id: item}) => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{pb: 2}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{fontSize: 22, fontFamily: "Montserrat", fontWeight: 800, textAlign: "center"}}>{item.nombre}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                <Typography sx={{fontSize: 16, fontWeight: 400, color: TEXTOSUAVE}}>{item.descripcion}</Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {item.items && item.items.map((item, index) => {
                        return (
                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item lg={2} sm={2} xs={2} sx={{marginTop: -2.8}}>
                                        <Typography sx={{
                                            fontSize: 80,
                                            fontWeight: 800,
                                            color: TEXTOACENTO
                                        }}>{index + 1}</Typography>
                                    </Grid>

                                    <Grid item lg={10} sm={10} xs={10}>
                                        <Typography sx={{fontSize: 14, fontWeight: 400}}>{item}</Typography>
                                    </Grid>


                                </Grid>
                            </Grid>
                        )
                    })}

                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 6}} >
                <BotonesAccion/>
            </Grid>



        </Grid>
    )
}

export default DetalleServicio;

