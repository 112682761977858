/**************************************************
 * Nombre:       BotonesAccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid} from "@mui/material";
import {irUrl} from "../../../Utilidades/irUrl";
import {LINKCAL, LINKWASS} from "../../../Constantes";
import {marcarEvento} from "../../../Servicios/Analiticas/marcarEvento";


const BotonesAccion = ({vertical = false, dir = "flex-start"}) => {



    const agendarLlamada = () => {
        irUrl(LINKCAL)
        marcarEvento('agendar_llamada')
    }

    const contactarAhora = () => {
        irUrl(LINKWASS)
        marcarEvento('chat_whatsapp')
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent={dir}
            alignItems="flex-start"
            spacing={vertical ? 3 : 4}
        >

            <Grid item container lg={vertical ? 12 : 6} sm={6} xs={6} sx={{justifyContent: dir}}>
                <Button
                    onClick={() => contactarAhora()}
                    sx={{width: "100%"}}
                >CONTACTAR AHORA</Button>
            </Grid>

            <Grid item container lg={vertical ? 12 : 6} sm={6} xs={6}
                  sx={{justifyContent: dir, marginTop: vertical ? 0 : 0}}>
                <Button
                    onClick={() => agendarLlamada()}
                    color={"info"}
                    variant={"outlined"}
                    sx={{width: "100%", color: "#000"}}
                >AGENDAR LLAMADA</Button>
            </Grid>

        </Grid>
    )
}

export default BotonesAccion;